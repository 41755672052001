import React, { useState, useEffect } from "react";
import "./MainView.css";
import {
    Add,
    CalendarMonth,
    Cancel,
    Check,
    Edit,
    FilterAlt,
    FormatListBulleted,
    KeyboardArrowDown,
} from "@mui/icons-material";
import CalendarView from "../Calendar/Calendar";
import {
    Alert,
    AppBar,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    FormControl,
    IconButton,
    Input,
    ListItemText,
    MenuItem,
    Select,
    Tooltip,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import ListView from "../ListView/ListView";
import Loader from "../Common/Loader/Loader";
import { getServiceAliases, getReleaseBasedOnJobs, getServiceMapping, getReleaseBasedServiceComponents, getReleaseBasedDependentServices, getServiceComponents, getDependentServices } from "../../Services";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { createColorCodeForRelease, sourceMapping, vendorDict, eventTypes } from "../../utility";
import UserPreference from "../UserPreference/UserPreference";
import TimelineView from "../TimelineView/TimelineView";

const MainView = ({ preference }) => {
    const navigate = useNavigate();
    const email = sessionStorage.getItem("email")
        ? sessionStorage.getItem("email")
        : null;
    const [isListView, setIsListView] = useState(false);
    const [isLoading, setLoader] = useState(true);
    const [events, setEvents] = useState([]);
    const [filtererdEvents, setFilteredEvents] = useState([]);
    const quickFilters = preference ? preference["quick_services"] : [];
    const [serviceObj, setServiceObj] = useState(null);
    const [filterServiceObj, setFilterServiceObj] = useState({});
    const [statusList, setStatusList] = useState([]);
    // const [categoryList, setCategoryList] = useState([]);
    const [categoryList, setCategoryList] = useState(['Release', 'Maintenance', 'Outage']);
    const [envList, setEnvList] = useState([]);
    const [colorCoding, setColorCoding] = useState(null);
    const [sources, setSources] = useState([]); // 'Enterprise','BFR',
    const [selectedSources, setSelectedSources] = useState([]);
    const [selectedService, setSelectedService] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [selectedEnv, setSelectedEnv] = useState([]);
    const [selectedQuickFilters, setSelectedQuickFilters] = useState([]);
    const [usedReleaseId, setUsedReleaseId] = useState(null);
    const [aliases, setAliases] = useState({});
    const [serviceComponents, setServiceComponents] = useState({});
    const [dependentServices, setDependentServices] = useState({});
    const [hasServiceComponentReleases, setHasServiceComponentReleases] = useState(false);
    const [hasDependentServiceReleases, setHasDependentServiceReleases] = useState(false);
    const [isNewUser, setNewUser] = useState(false);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [loadString, setLoadString] = useState('');
    const [isUserPreferenceOpen, setIsUserPreferenceOpen] = useState(false);
    const [openTab, setTab] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const { releaseId } = useParams();
    const loggedIn = sessionStorage.getItem("isLoggedIn") === 'true' ? true : false;

    useEffect(() => {
        if (!loggedIn && releaseId) {
            sessionStorage.setItem("path", `${releaseId}`);
            sessionStorage.setItem("directLogin", true);
            setLoader(true);
            navigate('/login');
        } else if (loggedIn && releaseId) {
            setUsedReleaseId(releaseId);
            sessionStorage.removeItem("path");
            sessionStorage.removeItem("directLogin");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn, releaseId]);

    useEffect(() => {
        if (window.location.pathname === "/") {
            setUsedReleaseId(null);
        }
    });


    useEffect(() => {
        if (!email) {
            console.log("user not found");
            navigate("/login");
        }
    }, []);

    useEffect(() => {
        const ele = document.body;
        if (ele && preference) {
            setIsListView(preference["view"] === "list");
            getServiceMenuList();
            // preference["theme"] === "dark"
            //     ? ele.classList.add("dark")
            //     : ele.classList.remove("dark");
            // const theme = preference["theme"] === "dark" ? "#7273f2" : "#3F40A3";
            // let r = document.querySelector(":root");
            // r.style.setProperty("--theme", theme);
        }
    }, [preference]);

    useEffect(() => {
        if (serviceObj && Object.keys(serviceObj).length > 0) {
            getSourceData();
        }
    }, [serviceObj]);

    const toggleView = (bool) => {
        setIsListView(bool);
    };

    const updateEvents = () => {
        let filterList =
          selectedQuickFilters.length > 0 ? filtererdEvents : [...events];
    
        if (selectedSources.length === 0) {
          filterList = [];
        } else if (selectedSources.length > 0) {
          if (selectedSources.length !== sources.length) {
            filterList = filterList.filter((event) => {
              return selectedSources.includes(event["Vendor_type"]);
            });
          }
          if (selectedService.length > 0) {
            filterList = filterServiceWithAllCriteria(selectedService, filterList);
          }
          if (selectedStatus.length > 0) {
            filterList = filterList.filter((event) => {
              return selectedStatus.includes(event["release_status"]);
            });
          }
          if (selectedCategory.length > 0) {
            filterList = filterList.filter((event) => {
              return selectedCategory.includes(event["release_category"]);
            });
          }
          if (selectedEnv.length > 0) {
            filterList = filterList.filter((event) => {
              return selectedEnv.includes(event["environment"]);
            });
          }
        }
        setFilteredEvents(filterList);
    };
    
    const filterServiceWithAllCriteria = (service, filterList) => {
        let allServicesWithSource = {};
        service.forEach((str) => {
          allServicesWithSource[str.toLowerCase()] = str;
        });

        const addServices = (source, servicesToAdd) => {
          servicesToAdd.forEach((str) => {
            const lowerStr = str.toLowerCase();
            if (!allServicesWithSource[lowerStr]) {
              allServicesWithSource[lowerStr] = source;
            }
          });
        };

        const filterServiceMapper = (lookup) => {
          for (const [key, values] of Object.entries(lookup)) {
            const lowerKey = key.toLowerCase();
            if (allServicesWithSource[lowerKey]) {
              addServices(allServicesWithSource[lowerKey], values);
            }
          }
        };

        filterServiceMapper(aliases);
        filterServiceMapper(serviceComponents);
        filterServiceMapper(dependentServices);

        return filterList.reduce((acc, event) => {
          const lowerVendorService = event["vendor_service"].toLowerCase();
          const matchedService = Object.keys(allServicesWithSource).find((str) =>
            lowerVendorService.includes(str)
          );
          if (matchedService) {
            event["parent_service"] = allServicesWithSource[matchedService];
            acc.push(event);
          }
          return acc;
        }, []);
    };
    const handleSourceChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedSources(typeof value === "string" ? value.split(",") : value);
    };

    const handleServiceChange = (event) => {
        const { value } = event.target;
        const allSelected = value.includes("all");
        setSelectedService(
            allSelected && value.length - 1 === filterServiceObj.length ? [] : allSelected ? filterServiceObj : value.filter(Boolean)
        );
    };
     

    const handleStatusChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedStatus(typeof value === "string" ? value.split(",") : value);
    };

    const handleCategoryChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedCategory(typeof value === "string" ? value.split(",") : value);
    };

    const handleEnvChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedEnv(typeof value === "string" ? value.split(",") : value);
    };

    const handleSearchClear = () => {
          setSearchValue("");
      };

    const addQuickFilters = (service) => {
        setSelectedQuickFilters([...selectedQuickFilters, service]);
    };

    const removeQuickFilters = (event, service) => {
        event.stopPropagation();
        let filter = selectedQuickFilters.filter((s) => s !== service);
        setSelectedQuickFilters(filter);
    };

    const removeSelectedService = () => {
        setSelectedService([]);
    };

    const removeSelectedStatus = () => {
        setSelectedStatus([]);
    };

    const removeSelectedCategory = () => {
        setSelectedCategory([]);
    };

    const removeSelectedEnv = () => {
        setSelectedEnv([]);
    };

    useEffect(() => {
        if (events.length) {
            updateEvents();
        }
    }, [selectedService, selectedStatus, selectedCategory, selectedEnv, selectedSources, aliases]);

    useEffect(() => {
        if (events.length) {
            let filterList = events;
            if (selectedSources.length !== sources.length) {
                filterList = filterList.filter((event) => {
                    return selectedSources.includes(event["Vendor_type"]);
                });
            }
            if (selectedQuickFilters.length > 0) {
                filterList = filterServiceWithAllCriteria(selectedQuickFilters, filterList);
            }
            setFilteredEvents(filterList);
        }
    }, [selectedQuickFilters]);

    // to filter services based on selected sources
    useEffect(() => {
        let obj = [];
        if (serviceObj && selectedSources.length === sources.length) {
            obj = Object.keys(serviceObj);
        } else if (serviceObj && selectedSources.length > 0) {
            obj = Object.keys(serviceObj).filter((service) => {
                return selectedSources.some(s => serviceObj[service].includes(s));
            });
        }
        setFilterServiceObj(obj);
        setSelectedService(obj);
    }, [selectedSources, serviceObj]);

  
    const getServiceAliasAndMapping = async (services) => {
        try {
            const body = { services: services };
            const [resp1, resp2, resp3, resp4] = await Promise.allSettled([getServiceAliases(body), getServiceMapping(body), getServiceComponents(body), getDependentServices(body)]);
            if (resp1 && resp1.value.data) {
                setAliases(resp1.value.data);
            }
            if (resp2 && resp2.value.data) {
                setServiceObj(Object.fromEntries(Object.entries(resp2.value.data).sort()));
            }
            if (resp3 && resp3.value.data) {
                setServiceComponents(Object.fromEntries(Object.entries(resp3.value.data).sort()));
            }
            if (resp4 && resp4.value.data) {
                setDependentServices(Object.fromEntries(Object.entries(resp4.value.data).sort()));
            }
        } catch (error) {
            console.log(error?.message);
            if (
                error?.response?.status === 401 ||
                error?.response?.data?.message === "User authorization failed."
            ){
                navigate("/logout");
            } else {
                navigate("/error");
            }
        }
        setLoader(false);
    }

    

    const getSourceData = async () => {
        let data = new Map(); 
        let allService = [...Object.keys(aliases)];
        Object.keys(aliases).forEach((str) => {
            allService = [...allService, ...aliases[str]];
        });
    
        try {
            for (let releaseType of sources) {
                setLoadString(releaseType.toLowerCase());
                if (vendorDict[releaseType] && preference[vendorDict[releaseType]]) {
                    const body = {
                        'showLongRelease': preference['show_long_release'],
                        'longReleaseDuration': preference['long_release'],
                        'releaseType': releaseType,
                        'services': preference[vendorDict[releaseType]],
                    }
                    const response = await getReleaseBasedOnJobs(body);
                    if (response && response.data) {
                        response.data.forEach(item => data.set(item.release_id, item));
                        updateStateWithData(data);
                    } else {
                        navigate("/error");
                    }
                }
            }
    
            data = await getComponentReleases(data, allService);
            updateStateWithData(data); 
            
            data = await getDependentReleases(data, allService);
            updateStateWithData(data); 

            setTimeout(() => {
                setDataLoaded(true);
                setLoadString('Data Loaded Successfully');
            }, 1000);
        } catch (error) {
            console.log(error?.message);
            setLoader(false);
            if (
                error?.response?.status === 401 ||
                error?.response?.data?.message === "User authorization failed."
            ){
                navigate("/logout");
            } else {
                navigate("/error");
            }
        }
    };
    
    const getComponentReleases = async (data, allService) => {
        try {
            setLoadString('Service Components');
            const body = {
                'showLongRelease': preference['show_long_release'],
                'longReleaseDuration': preference['long_release'],
                'services': allService,
            };
            const response = await getReleaseBasedServiceComponents(body);
            if (response && response.data) {
                const filterData = await filterSelectedInPreferences(response.data);
                setHasServiceComponentReleases(response.data.length !== filterData.length);
                filterData.forEach(item => data.set(item.release_id, item));
            } else {
                navigate("/error");
            }
        } catch (error) {
            console.log(error?.message);
            setLoader(false);
            if (error?.response?.status === 401 || error?.response?.data?.message === "User authorization failed.") {
                navigate("/logout");
            } else {
                navigate("/error");
            }
        }
        return data;
    };
    const getDependentReleases = async (data, allService) => {
        try {
            setLoadString('Dependent Service');
            const body = {
                'showLongRelease': preference['show_long_release'],
                'longReleaseDuration': preference['long_release'],
                'services': allService,
            };
            const response = await getReleaseBasedDependentServices(body);
            if (response && response.data) {
                const filterData = await filterSelectedInPreferences(response.data);
                setHasDependentServiceReleases(response.data.length !== filterData.length);
                filterData.forEach(item => data.set(item.release_id, item));
            } else {
                navigate("/error");
            }
        } catch (error) {
            console.log(error?.message);
            if (error?.response?.status === 401 || error?.response?.data?.message === "User authorization failed.") {
                navigate("/logout");
            } else {
                navigate("/error");
            }
        }
        return data;
    };
    
    const filterSelectedInPreferences = (data) => {
        return data.filter(release => {
            const vend = vendorDict[release["Vendor_type"]];
            return vend in preference && preference[vend] && preference[vend].includes(release["vendor_service"]);
        });
    };
    
    const updateStateWithData = (dataMap) => {
        const data = Array.from(dataMap.values()); 
        setColorCoding(createColorCodeForRelease(data));
        setEvents(data);
        setStatusList(getList(data, "release_status"));
        setEnvList(getList(data, "environment"));
    };
    
    useEffect(() => {
        if (statusList.length) {
            setSelectedStatus(statusList);
        }
    }, [statusList]);

    useEffect(() => {
        if (envList.length) {
            setSelectedEnv(envList);
        }
    }, [envList]);

    useEffect(() => {
        if (categoryList.length) {
            setSelectedCategory(categoryList);
        }
    }, [categoryList]);

    const getList = (data, key) => {
        let list = new Set();
        data.forEach((element) => {
            const keyValue = element[key];
            if (keyValue && !list.has(keyValue)) {
                list.add(keyValue);
            }
        });
        return Array.from(list).sort();
    };

    const getServiceMenuList = () => {
        let list = new Set();
        let source = new Set();
        Object.keys(vendorDict).map(vendor => {
            if (vendorDict[vendor] in preference && preference[vendorDict[vendor]]) {
                source.add(vendor);
                preference[vendorDict[vendor]].forEach((serv) => {
                    list.add(serv);
                });
            }
        });
        const serviceArr = Array.from(list).sort();
        if (serviceArr.length <= 0) {
            setNewUser(true);
            setIsUserPreferenceOpen(true);
        } else {
            getServiceAliasAndMapping(serviceArr);
            setSources(Array.from(source));
            setSelectedSources(Array.from(source));
        }
    };

    const editQuickFilters = () => {
        setTab(3);
        setIsUserPreferenceOpen(true);
    }

    const handleClosePreference = () => {
        setTab(null);
        setIsUserPreferenceOpen(false);
    };
    const closeAlert = () => {
        setHasDependentServiceReleases(false);
        setHasServiceComponentReleases(false);
    }

    const stopImmediatePropagation = (e) => {
        e.stopPropagation();
    };

    return (
        !isNewUser ? <>
            <AppBar
                position="static"
                sx={{
                    backgroundColor: "#000000",
                    borderTop: "1px solid #777",
                    height: "50px",
                }}
            >
                <div className="quick-filters justify-content-between d-flex h-100 px-4">
                    <div className="d-flex align-items-center pr-4">
                        <span className="text-light mr-2 font-weight-bolder">QUICK FILTERS: </span>
                        <ul className="navbar-nav flex-row">
                            {quickFilters?.map((i) => (
                                <li className="nav-item" key={i}>
                                    <Tooltip title={i}>
                                        <a
                                            className={`nav-link${selectedQuickFilters.indexOf(i) > -1 ? " active" : ""
                                                }${isDataLoaded ? "" : " disabled"}`}
                                            onClick={() => addQuickFilters(i)}
                                        >
                                            <span className="chip-text text-truncate">{i}</span>
                                            {
                                                selectedQuickFilters.indexOf(i) > -1 &&
                                                <IconButton
                                                    className="ml-1"
                                                    size="small"
                                                    sx={{ p: 0, color: "#9b9a9a" }}
                                                    onClick={($event) => removeQuickFilters($event, i)}
                                                >
                                                    <Cancel sx={{ fontSize: "16px" }} />
                                                </IconButton>
                                            }
                                        </a>
                                    </Tooltip>
                                </li>
                            ))}
                        </ul>
                        <IconButton
                            sx={{ color: "#888" }}
                            onClick={editQuickFilters}
                        >
                            <Tooltip title={'Edit quick filters'}>
                                <Edit fontSize="small" />
                            </Tooltip>
                        </IconButton>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        <FormControl className="source-list" size="small">
                            <Button
                                variant="contained"
                                disableElevation
                                sx={{ fontSize: "0.9rem" }}
                                className="font-weight-bolder"
                                size="small"
                                disabled={selectedQuickFilters.length > 0 || !isDataLoaded}
                                endIcon={<KeyboardArrowDown />}
                            >
                                Sources{" "}
                                {`${selectedSources.length > 0
                                    ? " | " +
                                    (selectedSources.length === sources.length
                                        ? "All"
                                        : (selectedSources.length > 3 ? selectedSources.slice(0, 3) + `, +${selectedSources.length - 3} more` : selectedSources))
                                    : ""
                                    }`}
                            </Button>
                            {
                                (isDataLoaded && colorCoding) && (
                                    <Select
                                        labelId="source"
                                        id="source"
                                        multiple
                                        value={selectedSources}
                                        className={`${selectedQuickFilters.length > 0 || !isDataLoaded ? "disabled" : ""
                                            }`}
                                        onChange={handleSourceChange}
                                        sx={{ width: "100%" }}
                                    >
                                        {sources.map((source) => (
                                            (
                                                colorCoding[source.toLowerCase()] ?
                                                    <MenuItem
                                                        className="d-flex align-items-center"
                                                        key={source} value={source}>
                                                        < Checkbox checked={selectedSources.indexOf(source) > -1} />
                                                        <span
                                                            className={`badge pl-2 pr-1 text-xs mr-2 ml-1`}
                                                            style={{
                                                                backgroundColor:
                                                                    colorCoding[source.toLowerCase()]["hex"],
                                                            }}
                                                        >
                                                            &nbsp;
                                                        </span>
                                                        <span className="text-truncate text-sm mt-1">
                                                        {sourceMapping[source.toLowerCase()]} 
                                                        {
                                                            source === "On-Prem DB" 
                                                            ? " User Events" 
                                                            : eventTypes.includes(source) 
                                                            ? " Events" 
                                                            : " Releases"
                                                        }
                                                        {source.length < 4 ? `(${source})` : ''}
                                                        </span>
                                                    </MenuItem> : <MenuItem
                                                        disabled
                                                        className="d-flex align-items-center"
                                                        key={source} value={source}>
                                                        <Checkbox checked={selectedSources.indexOf(source) > -1} />
                                                        <span className={`badge pl-2 pr-1 text-xs mr-2 ml-1 bg-secondary`}>
                                                            &nbsp;
                                                        </span>
                                                        <span className="text-truncate text-sm mt-1">
                                                            {sourceMapping[source.toLowerCase()]} 
                                                                {
                                                                    source === 'On-Prem DB' 
                                                                    ? ' User Events' 
                                                                    : eventTypes.includes(source) 
                                                                    ? ' Events' 
                                                                    : ' Releases'
                                                                }
                                                            {source.length < 4 ? `(${source})` : ''}
                                                        </span>
                                                    </MenuItem>
                                            )
                                        ))}

                                    </Select>
                                )
                            }
                        </FormControl>
                    </div>
                </div>
            </AppBar >
            <div className="main px-4 py-4">
                {isLoading ? (
                    <Loader />
                ) : (
                    <div className="">
                        <div className="d-flex align-items-start justify-content-between">
                            <div> {selectedService && (
                                <div className="d-flex align-items-center">
                                    <FilterAlt className="mr-2 text-secondary" />                                   
                                    <FormControl className="source-list" size="small">
                                        <Chip
                                            className={`chips mr-2 ${selectedQuickFilters.length > 0 || !isDataLoaded ? "disabled" : ""
                                                }`}
                                            label={`Business Service ${selectedService.length > 0
                                                ? " | " +
                                                (selectedService.length === filterServiceObj.length
                                                    ? "All"
                                                    : (selectedService.length > 2 ? selectedService.slice(0, 2) + `, +${selectedService.length - 2} more` : selectedService))
                                                : ""
                                                }`}
                                            onDelete={removeSelectedService}
                                        />
                                        <Select
                                            labelId="service"
                                            id="service"
                                            multiple
                                            className={`${selectedQuickFilters.length > 0 || !isDataLoaded ? "disabled" : ""
                                                }`}
                                            value={selectedService}
                                            onChange={handleServiceChange}
                                            MenuProps={{ autoFocus: false }}
                                            sx={{ width: "calc(100% - 40px)", borderRadius: "10px" }}
                                            onClose={() => setTimeout(handleSearchClear, 10)} 
                                        >
                                            <div className="d-flex flex-column mt-1 bg-white position-sticky bottom-0 pb-2 px-3">
                                                <div className="d-flex align-items-center position-relative">
                                                    <Input
                                                    onClickCapture={stopImmediatePropagation}
                                                    onKeyDown={stopImmediatePropagation}
                                                    value={searchValue}
                                                    onChange={(e) => setSearchValue(e.target.value)}
                                                    sx={{ fontSize: "0.9rem", lineHeight: "1.5", width: "100%", paddingRight: '30px' }}
                                                    placeholder="Search"
                                                    />
                                                    {searchValue && (
                                                        <IconButton
                                                        onClick={handleSearchClear}
                                                        sx={{
                                                            position: 'absolute',
                                                            right: '10px',
                                                            cursor: 'pointer',
                                                            padding: 0,
                                                            pb: '6px',
                                                        }}
                                                        aria-label="clear"
                                                        size="small"
                                                        >
                                                        <ClearIcon />
                                                        </IconButton>
                                                    )}
                                                </div>
                                            </div>
                                            <MenuItem sx={{ maxWidth: "350px" }} value="all">
                                                <Checkbox
                                                    checked={
                                                        selectedService.length === filterServiceObj.length
                                                    }
                                                    indeterminate={
                                                        selectedService.length > 0 &&
                                                        selectedService.length < filterServiceObj.length
                                                    }
                                                />
                                                <span className="text-truncate text-sm mt-1">
                                                    Select all
                                                </span>
                                            </MenuItem>
                                            {filterServiceObj.filter(serv => {
                                                if (!searchValue) return true;
                                                return serv.toLowerCase().includes(searchValue.toLowerCase());
                                            }).map((service) => (
                                                <MenuItem
                                                    title={service}
                                                    sx={{ maxWidth: "350px" }}
                                                    className="d-flex align-items-center"
                                                    key={service}
                                                    value={service}>
                                                    <Checkbox checked={selectedService && selectedService.includes(service)} />
                                                    <span className="text-truncate text-sm mt-1">
                                                        {service}
                                                    </span>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>                                    
                                    <FormControl className="source-list" size="small">
                                        <Chip
                                            className={`chips mr-2 ${selectedQuickFilters.length > 0 || !isDataLoaded ? "disabled" : ""
                                                }`}
                                            label={`Status ${selectedStatus.length > 0 ? " | " +
                                                (selectedStatus.length === statusList.length
                                                    ? "All"
                                                    : (selectedStatus.length > 2 ? selectedStatus.slice(0, 2) + `, +${selectedStatus.length - 2} more` : selectedStatus))
                                                : ""
                                                }`}
                                            onDelete={removeSelectedStatus}
                                        />
                                        <Select
                                            labelId="service"
                                            id="service"
                                            multiple
                                            className={`${selectedQuickFilters.length > 0 || !isDataLoaded ? "disabled" : ""
                                                }`}
                                            value={selectedStatus}
                                            onChange={handleStatusChange}
                                            sx={{ width: "calc(100% - 40px)", borderRadius: "10px" }}
                                        >
                                            {statusList.map((status) => (
                                                <MenuItem key={status} value={status}>
                                                    <Checkbox
                                                        checked={selectedStatus.indexOf(status) > -1}
                                                    />
                                                    <ListItemText primary={status} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl className="source-list" size="small">
                                        <Chip
                                            className={`chips mr-2 ${selectedQuickFilters.length > 0 || !isDataLoaded ? "disabled" : ""
                                                }`}
                                            label={`Category ${selectedCategory.length > 0
                                                ? " | " + (selectedCategory.length === categoryList.length
                                                    ? "All"
                                                    : (selectedCategory.length > 2 ? selectedCategory.slice(0, 2) + `, +${selectedCategory.length - 2} more` : selectedCategory))
                                                : ""
                                                }`}
                                            onDelete={removeSelectedCategory}
                                        />
                                        <Select
                                            labelId="service"
                                            id="service"
                                            multiple
                                            className={`${selectedQuickFilters.length > 0 || !isDataLoaded ? "disabled" : ""
                                                }`}
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                            sx={{ width: "calc(100% - 40px)", borderRadius: "10px" }}
                                        >
                                            {categoryList.map((category) => (
                                                <MenuItem key={category} value={category}>
                                                    <Checkbox
                                                        checked={selectedCategory.indexOf(category) > -1}
                                                    />
                                                    <ListItemText primary={category} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl className="source-list" size="small">
                                        <Chip
                                            className={`chips mr-2 ${selectedQuickFilters.length > 0 || !isDataLoaded ? "disabled" : ""
                                                }`}
                                            label={`Environment ${selectedEnv.length > 0
                                                ? " | " + (selectedEnv.length === envList.length
                                                    ? "All"
                                                    : (selectedEnv.length > 2 ? selectedEnv.slice(0, 2) + `, +${selectedEnv.length - 2} more` : selectedEnv))
                                                : ""
                                                }`}
                                            onDelete={removeSelectedEnv}
                                        />
                                        <Select
                                            labelId="service"
                                            id="service"
                                            multiple
                                            className={`${selectedQuickFilters.length > 0 || !isDataLoaded ? "disabled" : ""
                                                }`}
                                            value={selectedEnv}
                                            onChange={handleEnvChange}
                                            sx={{ width: "calc(100% - 40px)", borderRadius: "10px" }}
                                        >
                                            {envList.map((env) => (
                                                <MenuItem key={env} value={env}>
                                                    <Checkbox
                                                        checked={selectedEnv.indexOf(env) > -1}
                                                    />
                                                    <ListItemText primary={env} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            )
                            }
                            </div>
                            <div className="d-flex align-items-center">
                                {
                                    loadString && (
                                        !isDataLoaded ? <div className="badge badge-primary d-flex align-items-center">
                                            <p className="text-sm mb-0 ml-1">
                                                Fetching {loadString in sourceMapping ? sourceMapping[loadString] : loadString} 
                                                    {
                                                        loadString in sourceMapping && sourceMapping[loadString] === 'On-Prem DB' 
                                                        ? ' User Events' 
                                                        : eventTypes.includes(sourceMapping[loadString]) 
                                                        ? ' Events' 
                                                        : ' Releases'
                                                    }...
                                            </p>
                                            <CircularProgress className="mx-2 text-white" size="1rem" />
                                        </div> : <div className="badge badge-success text-sm d-flex align-items-center">{loadString}<Check className="ml-1 text-white" sx={{ fontSize: '1.2rem' }} /></div>
                                    )
                                }
                                <h6 className="mb-0 ml-3 mr-2 text-secondary text-sm">
                                    {isListView ? "List View" : "Calendar View"} :
                                </h6>
                                <div
                                    className="btn-group btn-group-sm"
                                    role="group"
                                    aria-label="Basic example"
                                >
                                    <button
                                        type="button"
                                        className={`btn btn-sm btn-outline-secondary ${isListView ? "" : "active"
                                            }`}
                                        onClick={() => toggleView(false)}
                                    >
                                        <CalendarMonth fontSize="small" />
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn btn-sm btn-outline-secondary ${isListView ? "active" : ""
                                            }`}
                                        onClick={() => toggleView(true)}
                                    >
                                        <FormatListBulleted fontSize="small" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            (hasDependentServiceReleases || hasServiceComponentReleases) && <Alert severity="info" className="text-sm mt-3" onClose={() => { closeAlert() }}>
                                Your preferences are currently not allowing to show some of the updates related to Service Component(s) /  Dependent Service(s) of selected Business Service(s). Please update your preference to see more related updates.
                            </Alert>
                        }
                        <div className="min-h-list">
                            {isListView ? (
                                <>
                                    <TimelineView isDataLoaded={isDataLoaded} events={filtererdEvents}/>
                                    <ListView isDataLoaded={isDataLoaded} events={filtererdEvents} {...(releaseId ? { releaseId: usedReleaseId } : {})} />
                                </>
                            ) : (
                                <CalendarView isDataLoaded={isDataLoaded} events={filtererdEvents} preference={preference} {...(releaseId ? { releaseId: usedReleaseId } : {})} />
                            )}
                        </div>
                    </div>
                )}
            </div>
            <ToastContainer />
            {
                openTab && <UserPreference
                    open={isUserPreferenceOpen}
                    onClose={handleClosePreference}
                    preferences={preference}
                    isNewUser={isNewUser}
                    openTab={openTab}
                />
            }
        </> : (
            preference && <UserPreference
                open={isUserPreferenceOpen}
                onClose={handleClosePreference}
                preferences={preference}
                isNewUser={isNewUser}
                openTab={openTab}
            />
        )
    );
};

export default MainView;