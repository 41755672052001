import React, { useEffect, useState } from 'react';
import "./EventsList.css";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Tab, Tabs, Tooltip } from '@mui/material';
import EventDetails from '../Common/EventDetails/EventDetails';
import { ExpandMore } from '@mui/icons-material';
import { getSourceLastUpdatedTime } from "../../Services";
import moment from 'moment-timezone';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const EventsList = ({ isDataLoaded, selectedDay, events, event }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [expanded, setExpanded] = useState('');
  const [sourceDict, setSourceDict] = useState({});
  const [lastUpdated, setLastUpdated] = useState('');

  useEffect(() => {
    if (event) {
      setIsModalOpen(true);
    }
    if (isDataLoaded) setSelectedEvent(event);
  }, [event, isDataLoaded]);

  function getLastUpdatedTime(sourceDict, lastUpdated, source) {
    if (!sourceDict.hasOwnProperty(source) || !lastUpdated.hasOwnProperty(source)) {
      return "";
    }
    return moment(lastUpdated[source]).format('DD MMM, YYYY HH:MM:SS');
  }

  useEffect(() => {
    const updatedTimeFromSource = async () => {
      try {
        let updatedTimeFromSource = await getSourceLastUpdatedTime();

        if (updatedTimeFromSource && updatedTimeFromSource.data) {
          setLastUpdated(updatedTimeFromSource?.data);
        }
      } catch (error) {
        console.error("Error fetching updated time:", error);
      }

    };
    updatedTimeFromSource();
  }, []);

  useEffect(() => {
    let source = {};
    events.forEach((element) => {
      if (!(element["Vendor_type"] in source)) {
        source[element["Vendor_type"]] = [element];
      } else {
        source[element["Vendor_type"]].push(element);
      }
    });
    source = Object.keys(source)
      .sort().reverse().reduce((accumulator, key) => {
        accumulator[key] = source[key];
        return accumulator;
      }, {});
    setSourceDict(source);
    const firstSource = Object.keys(source);
    if (firstSource) {
      setExpanded(firstSource[0]);
    }
  }, [events]);

  const toggleDrawer = (event, bool) => {
    setSelectedEvent(event);
    setIsModalOpen(bool);
  };

  const getDate = (date) => {
    return date.getDate();
  };

  const getMonth = (date) => {
    return date.toLocaleString('en-us', { month: 'short' });
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className='card h-100'>
      <Tabs
        className='px-3 border-bottom'
        value={tabValue}
        onChange={handleTabChange}
        aria-label="basic tabs"
      >
        <Tab label={`Bulletin board`} {...a11yProps(0)} />
        <Tab label={`Releases`} {...a11yProps(1)} />
      </Tabs>
      <div className="p-3">
        <CustomTabPanel value={tabValue} index={0}>
          <Alert severity="info">
            We will be live soon.
          </Alert>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <div className='events-accordion'>
            {
              events.length <= 0 && (
                <Alert severity="warning" className='mx-3'>
                  No events found on {getDate(selectedDay)} {getMonth(selectedDay)}.
                </Alert>
              )
            }
            {
              Object.keys(sourceDict).map((source) => (
                <Accordion sx={{ backgroundColor: '#EFEFEF' }} key={source} expanded={expanded === source} onChange={handleChange(source)}>
                  <AccordionSummary aria-controls={source} expandIcon={<ExpandMore />} id={source}>
                    <div className='d-flex align-items-center w-100'>
                      <div>{source}<small className='text-s ml-2' style={{ marginTop: 1 }}>({sourceDict[source].length})</small></div>
                      <small className='font-italic ml-auto mr-1'>Last synced at {getLastUpdatedTime(sourceDict, lastUpdated, source)} UTC</small>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="events-list">
                      {sourceDict[source].map((event, index) => (
                        <div key={`${event.id}-${index}`} >
                          <a
                            onClick={() => toggleDrawer(event, true)}
                            className="event">
                            <div className="bg-white font-weight-bold p-2 rounded mr-2 text-center w-70 h-70 text-body">
                              <p className="mb-0">{getMonth(selectedDay)}</p>
                              <h3 className="font-weight-bold mb-0">{getDate(selectedDay)}</h3>
                            </div>
                            <div className="event-details ml-2 text-truncate">
                              <Tooltip title={event.title}>
                                <p className="mb-0 text-truncate mt-1" style={{ fontWeight: 600 }}>{event.title}</p>
                              </Tooltip>
                              <div className="d-flex align-items-center text-secondary text-s">
                                <span className="">Release No: {event.release_id}<span className='mx-2'>|</span></span>
                                <span className="text-truncate">End Date: {(new Date(event.planned_end_date) != 'Invalid Date') ? new Date(event.planned_end_date).toDateString().slice(4, 16) : 'NA'}</span>
                              </div>
                              {
                                event.vendor_service && (
                                  <div><small className='badge font-weight-normal' style={{ backgroundColor: event.color['hex'], color: event.color['isDark'] ? '#fff' : '#000' }}> {event.vendor_service}</small></div>
                                )
                              }
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))
            }
            {
              selectedEvent && (
                <EventDetails isDataLoaded={isDataLoaded} event={selectedEvent} isOpen={isModalOpen} setIsModalOpen={toggleDrawer} />
              )
            }
          </div>
        </CustomTabPanel>
      </div>
    </div>
  );
};

export default EventsList;