import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    Divider,
    ListItemText,
} from "@mui/material";
import {
    Logout,
    ManageAccounts,
    NotificationsNoneOutlined,
    Settings,
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../SearchBar";
import Notifications from "../Notifications";

import "./Header.css";
import { getUserPreferences } from "../../../Services";
import UserPreference from "../../UserPreference/UserPreference";
import { clearStorageData } from "../../../utility";

const Header = ({ setPreferences }) => {
    const navigate = useNavigate();

    const [searchResults, setSearchResults] = useState([]);
    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    const [userPreferences, setUserPreferences] = useState(null);
    const [isUserPreferenceOpen, setIsUserPreferenceOpen] = useState(false);
    const [avataranchorEl, setAvatarAnchorEl] = React.useState(null);
    const avatarOpen = Boolean(avataranchorEl);

    let email = sessionStorage.getItem("email");
    let username = sessionStorage.getItem("username");
    let avatarName = username
        ?.split(" ")
        .map((word) => word.charAt(0))
        .join("").slice(0,2);
    const loggedIn = sessionStorage.getItem("isLoggedIn") === 'true' ? true : false;
    const getTokenFromStorage = () => {
    return sessionStorage.getItem("token");
    };
    const token = getTokenFromStorage();

    useEffect(() => {
      if (loggedIn && token) {
        getUserPrefence();
      }
    }, [loggedIn, token]);

    const handleLogout = () => {
        clearStorageData();
        navigate("/login");
    };

    const getUserPrefence = async () => {
        try {
            const response = await getUserPreferences(email);
            if (response && response.data) {
                setUserPreferences(response.data);
                setPreferences(response.data);
                sessionStorage.setItem("preference", JSON.stringify(response.data));
            }
        } catch (error) {
            console.log(error);
            if (
                error?.response?.status === 401 ||
                error?.response?.data?.message === "User authorization failed."
            ) {
                navigate("/logout");
            } else {
                navigate("/error");
            }
        }
    };

    const handleAvatarClick = (event) => {
        setAvatarAnchorEl(event.currentTarget);
    };
    const handleAvatarClose = () => {
        setAvatarAnchorEl(null);
    };

    const handleNotificationsClick = () => {
        setIsNotificationsOpen(true);
    };

    const closeNotifications = () => {
        setIsNotificationsOpen(false);
    };

    const handleUserPreferenceClick = () => {
        handleAvatarClose();
        setIsUserPreferenceOpen(true);
    };

    const handleClosePreference = () => {
        setIsUserPreferenceOpen(false);
    };

    const dummyNotifications = [
        {
            id: 1,
            title:
                'Microsoft Office 365: "New updates are available for Microsoft Office 365. Check out the latest features and improvements."',
            timestamp: "2023-09-26 10:30 AM",
            read: false,
        },
        {
            id: 2,
            title:
                'Microsoft Azure: "Important maintenance is scheduled for Microsoft Azure services. Please plan your activities accordingly."',
            timestamp: "2023-09-25 3:45 PM",
            read: false,
        },
        {
            id: 3,
            title:
                'SharePoint: "Recent changes have been made to your SharePoint site. Review the updates to ensure smooth collaboration."',
            timestamp: "2023-09-25 3:45 PM",
            read: false,
        },
    ];

    const handleSearch = (query) => {
        const dummyResults = [
            {
                id: 1,
                title: "Microsoft Office 365",
                description: "Productivity Suite",
            },
            {
                id: 2,
                title: "Microsoft Azure",
                description: "Cloud Platform",
            },
            {
                id: 3,
                title: "Windows 10",
                description: "Operating System",
            },
            {
                id: 4,
                title: "Microsoft Teams",
                description: "Team Collaboration",
            },
            {
                id: 5,
                title: "SharePoint",
                description: "Content Management",
            },
        ];

       // setSearchResults(dummyResults);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: "#000000" }}>
            <Toolbar>
                <a className="navbar-brand" href="/">
                    <img
                        src="https://autobots-app-prd.s3.amazonaws.com/web/static/autodesk-logo-primary-rgb-white-large.png"
                        className="img-responsive"
                        style={{ width: "175px" }}
                        alt="AutoDesk"
                    />
                </a>
                <Typography
                    component="div"
                    sx={{
                        flexGrow: 1,
                        fontWeight: "500",
                        fontSize: "1.2rem",
                        marginTop: "0.2rem",
                    }}
                >
                    Enterprise Applications Update Calendar
                </Typography>

                {loggedIn && (
                    <div className="d-flex align-items-center text-sm">
                        {/* <SearchBar
                            onSearch={handleSearch}
                            searchResults={searchResults}
                            setSearchResults={setSearchResults}
                        /> */}
                        <IconButton
                            sx={{ color: "#888", marginRight: "6px", marginLeft: "6px" }}
                            onClick={handleNotificationsClick}
                        >
                                <NotificationsNoneOutlined />
                        </IconButton>
                        <Notifications
                            open={isNotificationsOpen}
                            onClose={closeNotifications}
                            notifications={dummyNotifications}
                        />
                        <Stack direction="row" alignItems={"center"}>
                            <IconButton
                                sx={{ color: "#fff", p: 0 }}
                                id="fade-button"
                                aria-controls={avatarOpen ? "fade-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={avatarOpen ? "true" : undefined}
                                onClick={handleAvatarClick}
                            >
                                {loggedIn && (
                                    <Tooltip
                                        title={`User: ${username ? username : "Not Logged in"}`}
                                    >
                                        <Avatar
                                            sx={{
                                                backgroundColor: "#a37aff96",
                                                width: "32px",
                                                height: "32px",
                                                fontSize: "0.9rem",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {avatarName}
                                        </Avatar>
                                    </Tooltip>
                                )}
                            </IconButton>
                        </Stack>
                        <Menu
                            anchorEl={avataranchorEl}
                            id="account-menu"
                            open={avatarOpen}
                            onClose={(e, reason) => {
                                if (reason === "backdropClick") {
                                    setAvatarAnchorEl(null);
                                }
                            }}
                            onClick={handleAvatarClose}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            sx={{ top: "40px !important" }}
                        >
                            <Tooltip
                                title={`User: ${username ? username : "Not Logged in"} | ${email ? `Email: ${email}` : ""}`}
                                placement="top-start"
                            >
                            <MenuItem onClick={handleAvatarClose}>
                                <Avatar sx={{ height: "25px", width: "25px", mr: 1.5 }} />{" "}
                                Profile
                            </MenuItem>
                            </Tooltip>
                            <Divider />
                            <MenuItem onClick={handleUserPreferenceClick} sx={{ minWidth: "180px" }}>
                                <ListItemIcon>
                                    <ManageAccounts fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography>Preferences</Typography>
                                </ListItemText>
                            </MenuItem>
                            {/* <MenuItem onClick={handleAvatarClose}>
                                <ListItemIcon>
                                    <Settings fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography>Settings</Typography>
                                </ListItemText>
                            </MenuItem> */}
                            <MenuItem onClick={handleLogout}>
                                <ListItemIcon>
                                    <Logout fontSize="small" sx={{ ml: 0.3 }} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography>Logout</Typography>
                                </ListItemText>
                            </MenuItem>
                        </Menu>
                        {
                            userPreferences && <UserPreference
                                open={isUserPreferenceOpen}
                                onClose={handleClosePreference}
                                preferences={userPreferences}
                            />
                        }
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
