import axios from "axios";
import { REACT_APP_API } from "../environment";

const getTokenFromStorage = () => {
  return sessionStorage.getItem("token");
};
const token = getTokenFromStorage();

let headers = {
  "Content-Type": "application/json",
  "Authorization": token,
};

export const getParentServices = () => {
  return axios.get(`${REACT_APP_API}/getServiceEntity`, { headers: headers });
}

export const getReleaseDetails = (id, service) => {
  headers['id'] = id;
  headers['service'] = service;
  return axios.get(`${REACT_APP_API}/release_details`, { headers: headers });
}

export const getSourceLastUpdatedTime = () => {
  return axios.get(`${REACT_APP_API}/lastupdated`, { headers: headers });
}

export const getActiveSources = () => {
  return axios.get(`${REACT_APP_API}/activeSources`, { headers: headers });
}

export const shareRelease = (body) => {
  return axios.post(`${REACT_APP_API}/share_release`, body, { headers: headers });
}

export const getServiceAliases = (body) => {
  return axios.post(`${REACT_APP_API}/serviceAliases`, body, { headers: headers });
}

export const getServiceMapping = (body) => {
  return axios.post(`${REACT_APP_API}/serviceMapping`, body, { headers: headers });
}

export const getServiceComponents = (body) => {
  return axios.post(`${REACT_APP_API}/serviceComponents`, body, { headers: headers });
}

export const getDependentServices = (body) => {
  return axios.post(`${REACT_APP_API}/dependentServices`, body, { headers: headers });
}

export const getReleaseBasedOnJobs = (body) => {
  return axios.post(`${REACT_APP_API}/jobBasedReleases`, body, { headers: headers });
}

export const getReleaseBasedServiceComponents = (body) => {
  return axios.post(`${REACT_APP_API}/serviceComponentReleases`, body, { headers: headers });
}

export const getReleaseBasedDependentServices = (body) => {
  return axios.post(`${REACT_APP_API}/dependentServicesReleases`, body, { headers: headers });
}

export const toggleUpvote = (body) => {
  return axios.post(`${REACT_APP_API}/toggle_upvote`, body, { headers: headers });
}

export const getSubscribedReleases = (body) => {
  return axios.post(`${REACT_APP_API}/subscribed_releases`, body, { headers: headers });
}