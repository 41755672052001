
import React from "react";
import { Button, CssBaseline, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import LoginIcon from "@mui/icons-material/Login";
import { clearStorageData } from "../../utility";
const StyledTypography = styled(Typography)({
  marginBottom: (theme) => theme.spacing(4),
  textAlign: "center",
});

const ErrorPage = () => {
  const navigate = useNavigate();
  clearStorageData();

  const handleLoginRedirect = () => {
    // Redirect to the login page
    clearStorageData();
    navigate("/login");
  };

  return (
    <div className="logout-container">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Paper
          elevation={5}
          square={false}
          sx={{ padding: 2.5 }}
          className="logout-container-paper"
        >
          <CssBaseline />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <RunningWithErrorsIcon
              sx={{ color: "#ff00008a", fontSize: "50px" }}
            />
            <StyledTypography color={"white"}>
              Something went wrong, dont't worry a notification has been sent to
              the team to check the issue.
            </StyledTypography>
            <StyledTypography color={"white"} variant="h6">
              If the issue persists, try after some time.
            </StyledTypography>
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoginRedirect}
              endIcon={<LoginIcon />}
            >
              Click to Login
            </Button>
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default ErrorPage;
