import React, { useEffect, useState } from 'react';
import { Alert, Box, IconButton, Rating, Stack, Tab, Tabs, alpha, styled } from '@mui/material';
import { Square, StarBorder } from '@mui/icons-material';
import { DataGrid, gridClasses, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import EventDetails from '../Common/EventDetails/EventDetails';
import "./ListView.css";

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : alpha(theme.palette.grey[100]),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                .2 + theme.palette.action.selectedOpacity,
            ),
        },
    },
    [`& .${gridClasses.row}.odd`]: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[500] : '#fff',
        '&:hover, &.Mui-hovered': {
            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[100],
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                .2 + theme.palette.action.selectedOpacity,
            ),
        },
    },
}));

export const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#333',
    },
    '& .MuiRating-iconHover': {
        color: '#333',
    },
});

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
            className="d-flex align-items-center justify-content-between grid-toolbar"
        >
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
            </GridToolbarContainer>
            <GridToolbarQuickFilter
                quickFilterParser={(searchInput) => {
                    return searchInput
                        .split(',')
                        .map((value) => value.trim())
                        .filter((value) => value !== '')
                }
                }
            />
        </Box>
    );
}

const ListView = ({ isDataLoaded, events, releaseId }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [visibleEvents, setVisibleEvents] = useState([]);

    useEffect(() => {
        if (releaseId && window.location.pathname !== "/" && isDataLoaded) {
            const event = events.find(e => e.release_id === releaseId);
            if (event && isDataLoaded) {
                setSelectedEvent(event);
                setIsModalOpen(true);
            }
        }
    }, [releaseId, isDataLoaded]);

    useEffect(() => {
        handleChange({}, 0);
    }, [events]);

    const toggleDrawer = (event, bool) => {
        setSelectedEvent(event);
        setIsModalOpen(bool);
    };

    const FavoriteButton = () => {
        return (
            < IconButton type="button" aria-label="favorite">
                <StarBorder sx={{ fontSize: '20px', color: '#ddd' }} />
            </IconButton>
        );
    };

    const Relevance = (params) => {
        if (params.value)
            return (
                <div className='d-flex align-items-center'>
                    <StyledRating
                        name="customized-color"
                        defaultValue={params.value ? (params.value === 'low' ? 1 : (params.value === 'medium' ? 2 : 3)) : 0}
                        disabled
                        max={3}
                        icon={<Square sx={{ fontSize: '16px' }} />}
                        emptyIcon={<Square sx={{ fontSize: '16px' }} />}
                    /> <span className='text-s ml-2 text-capitalize'>{params.value}</span>
                </div>
            );
        else return '-';
    };

    const Status = (params) => {
        return (
            <span className={params.value.toUpperCase() === 'COMPLETED' ? 'text-success' : (params.value.toUpperCase() === 'PLANNED' ? 'text-secondary' : 'text-info')}>
                {params.value}
            </span>
        );
    };

    const Service = (params) => {
        return (
            <div><small className='badge font-weight-normal' title={params.value} style={{ backgroundColor: params.row.color['hex'], color: params.row.color['isDark'] ? '#fff' : '#000' }}> {params.value}</small></div>
        );
    };

    const Title = (params) => {
        return (
            <a className='cursor-pointer text-primary text-truncate' title={params.value} onClick={() => toggleDrawer(params.row, true)}> {params.value}</a>
        );
    };

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        let list = [];
        const today = new Date().setHours(0, 0, 0, 0);
        if (newValue === 0) {
            list = events.filter(e => new Date(e.planned_end_date).setHours(0, 0, 0, 0) >= today);
        } else if (newValue === 1) {
            list = events.filter(e => {
                // include invalid dates also to archive
                return new Date(e.planned_end_date) == 'Invalid Date' || new Date(e.planned_end_date).setHours(0, 0, 0, 0) < today;
            });
        }
        setVisibleEvents(list);
    };

    const columns = [
        {
            field: 'release_id',
            headerName: 'ID',
            width: 110,
        },
        {
            field: 'title',
            headerName: 'Title',
            width: 400,
            renderCell: (params) => <Title {...params} />,
        },
        // {
        //     field: '',
        //     headerName: 'Favorite',
        //     width: 120,
        //     renderCell: (params) => <FavoriteButton />,
        // },
        {
            field: 'vendor_service',
            headerName: 'Service',
            width: 120,
            renderCell: (params) => <Service {...params} />,
        },
        {
            field: 'planned_start_date',
            headerName: 'Start Date',
            width: 120,
            valueGetter: (params) => {
                return (new Date(params.value) != 'Invalid Date') ? new Date(params.value).toDateString().slice(4, 16) : 'NA';
            },
            valueFormatter: (params) =>
                `${params.value}`,
        },
        {
            field: 'planned_end_date',
            headerName: 'End Date',
            width: 120,
            type: 'date',
            hideable: true,
            valueGetter: (params) => {
                return (new Date(params.value) != 'Invalid Date') ? new Date(params.value).toDateString().slice(4, 16) : 'NA';
            },
            valueFormatter: (params) =>
                `${params.value}`,
        },
        {
            field: 'last_updated',
            headerName: 'Last Updated',
            width: 120,
            valueGetter: (params) => {
                return (new Date(params.value) != 'Invalid Date') ? new Date(params.value).toDateString().slice(4, 16) : 'NA';
            },
            valueFormatter: (params) =>
                `${params.value}`,
        },
        {
            field: 'environment',
            headerName: 'Environemnt',
        },
        {
            field: 'relevance',
            headerName: 'Impact',
            width: 150,
            valueGetter: (params) => {
                return params.value ? params.value.toLowerCase() : null;
            },
            renderCell: (params) => <Relevance {...params} />,
        },
        {
            field: 'release_status',
            headerName: 'Status for your org',
            width: 150,
            renderCell: (params) => <Status {...params} />,
        },
        {
            field: 'release_category',
            headerName: 'Category',
            width: 130,
            valueGetter: (params) =>
                `${params.value || 'N/A'}`,
        },
        {
            field: 'platform',
            headerName: 'Platform',
            width: 150,
            valueGetter: (params) =>
                `${params.value || 'N/A'}`,
        },
        {
            field: 'impacted_services',
            headerName: 'Impacted Service',
            width: 200,
            valueGetter: (params) =>
                `${params.value || 'N/A'}`,
        },
        {
            field: 'adsk_business_service',
            headerName: 'Business Service',
            width: 200,
            valueGetter: (params) =>
                `${params.value || 'N/A'}`,
        },
        {
            field: 'act_by',
            headerName: 'Act By',
            width: 100,
            valueGetter: (params) =>
                `${params.value || 'N/A'}`,
        },
        {
            field: 'geo',
            headerName: 'Geo',
            valueGetter: (params) =>
                `${params.value || 'N/A'}`,
        },
        {
            field: 'what_action_needed',
            headerName: 'What Action Needed',
            width: 200,
            valueGetter: (params) =>
                `${params.value || 'N/A'}`,
        },
    ];

    return (
        <div className=''>
            <Box className="mb-1 mt-4" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={
                        <React.Fragment>
                            <span className='d-flex'>Inbox <span style={{ fontSize: "smaller" }} className='ml-1'>({tabValue === 0 ? visibleEvents.length : events.length - visibleEvents.length})</span></span>
                        </React.Fragment>}
                        {...a11yProps(0)} />
                    <Tab label={
                        <React.Fragment>
                            <span className='d-flex'>Archive <span style={{ fontSize: "smaller" }} className='ml-1'>({tabValue === 1 ? visibleEvents.length : events.length - visibleEvents.length})</span></span>
                        </React.Fragment>}
                        {...a11yProps(1)} />
                </Tabs>
            </Box>
            <div className="mt-1">
                {
                    events.length <= 0 ? (
                        <Alert severity="warning" className=''>
                            No events found.
                        </Alert>
                    ) : (
                        <div style={{ height: '100%', width: '100%' }}>
                            <StripedDataGrid
                                sx={{
                                    borderColor: 'rgba(224, 224, 224, 1)',
                                    '& .MuiDataGrid-cell:focus': {
                                        outline: 'none',
                                    },
                                }}
                                rows={visibleEvents}
                                columns={columns}
                                getRowClassName={(params) =>
                                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                                }
                                getRowId={(row) => row.release_id}
                                rowHeight={32}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 15,
                                        },
                                    },
                                    columns: {
                                        columnVisibilityModel: {
                                            impacted_services: false,
                                            adsk_business_service: false,
                                            platform: false,
                                            act_by: false,
                                            release_category: false,
                                            geo: false,
                                        }
                                    },
                                }}
                                slots={{
                                    toolbar: QuickSearchToolbar,
                                    noResultsOverlay: () => (
                                        <Stack height="100%" alignItems="center" justifyContent="center">
                                            No rows to show.
                                        </Stack>
                                    ),
                                }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                    },
                                }}
                                pageSizeOptions={[15, 30, 50, 100]}
                                // checkboxSelection
                                disableRowSelectionOnClick
                                disableColumnFilter
                                disableDensitySelector
                            />

                            {
                                selectedEvent && (
                                    <EventDetails event={selectedEvent} isOpen={isModalOpen} setIsModalOpen={toggleDrawer} />
                                )
                            }
                        </div>
                    )
                }

            </div>
        </div>
    );
};

export default ListView;
