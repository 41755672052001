import dayjs from "dayjs";
import { eventTypes } from "../../utility/consts";

function getImpactBars(relevance) {
  if (!relevance) return "-";
  const levels = { low: 1, medium: 2, high: 3 };
  return Array(3).fill("#b6b6b6").map((color, index) => (index < levels[relevance.toLowerCase()] ? "#454545" : color));
}

function filterMatchingEvents(events, params) {
  return events.filter(e => {
    const eventDate = eventTypes.includes(e.Vendor_type) 
    ? new Date(e.planned_end_date).getTime() 
    : new Date(e.planned_start_date).getTime();  

    return eventDate === params.value[0] && e.parent_service === params.name && !e.isDummy;
  });
}


function setActivePageButton(params, currentPage) {
  document.querySelectorAll(".page-item").forEach(item => item.classList.remove("active"));
  const activeButton = document.getElementById(`tooltip-button-${params.dataIndex}-${currentPage}`);
  if (activeButton) {
    Object.assign(activeButton.style, {
      backgroundColor: "black", color: "white", outline: "none", border: "none", fontSize: "14px",
    });
    activeButton.parentElement.classList.add("active");
  }
}

function createPageButton(page, isActive, isDisabled, params) {
  return `
    <li class="page-item ${isActive ? "active" : ""} ${isDisabled ? "disabled" : ""}">
      <button id="tooltip-button-${params.dataIndex}-${page}" class="page-link rounded-lg mx-2 py-1 border-0 focus-0" data-page="${page}" style="background: ${isActive ? "black" : "transparent"}; color: ${isActive ? "white" : "black"}; font-size: 14px;">${page}</button>
    </li>`;
}

function createPaginationButtons(params, totalPages, currentPage, maxSize = 4) {
  if (totalPages <= 1) return "";
  let startPage = Math.max(1, Math.min(currentPage - Math.floor(maxSize / 2), totalPages - maxSize + 1));
  let endPage = Math.min(totalPages, startPage + maxSize - 1);
  let buttons = `
    <li class="page-item ${currentPage === 1 ? "disabled" : ""}">
      <button id="prev-button" class="page-link border-0 px-3 py-1 bg-transparent text-primary text-body-sm" data-page="${currentPage - 1}">&#8592;</button>
    </li>`;
  for (let i = startPage; i < endPage; i++) buttons += createPageButton(i, i === currentPage, false, params);
  if (endPage < totalPages && currentPage + 1 !== totalPages) buttons += `<li class="page-item disabled"><span class="page-link text-black border-0 px-3 py-1 text-body-sm">...</span></li>`;
  buttons += `${createPageButton(totalPages, false, false, params)}`;
  buttons += `
    <li class="page-item ${currentPage === totalPages ? "disabled" : ""}">
      <button id="next-button" class="page-link border-0 px-3 py-1 bg-transparent text-primary text-black text-body-sm" data-page="${currentPage + 1}">&#8594;</button>
    </li>`;
  return buttons;
}

function createTooltipContent(params, totalPages, matchingEvents) {
  if (matchingEvents.length === 0 || matchingEvents[0].isDummy) return "";
  const marginBottomClass = matchingEvents.length === 1 ? "mb-2" : "mb-4";
  return `
    <div class="tooltip-container bg-white p-3">
      <div class="tooltip-triangle position-absolute" style="border-left: 50px solid ${params.color};"></div>
      <div class="tooltip-animate">
      <div class="tooltip-header pb-3 border-bottom">
        <span class="font-weight-bold" id="vendor-type">Source | ${matchingEvents[0].Vendor_type}</span>
        <span class="d-block mt-2 text-body-1 font-weight-bold " id="event-title"><strong>${matchingEvents[0].title}</strong></span>
        <span class="d-inline-block text-white text-xs badge font-weight-normal" id="vendor-service" style="background-color: ${matchingEvents[0].color.hex};">${matchingEvents[0].vendor_service}</span>
      </div>
      <div class="tooltip-details">
        <div class="row d-flex justify-content-between p-0 m-0 pt-3 pb-1 px-1">
          <div class="col-4 font-weight-bold text-s text-body-color"><strong>RELEASE ID</strong></div>
          <div class="col-4 pl-2 font-weight-bold text-s text-body-color"><strong>STATUS</strong></div>
          <div class="col-4 font-weight-bold text-s text-body-color"><strong>LAST UPDATED</strong></div>
        </div>
        <div class="row d-flex justify-content-between p-0 m-0 border-bottom px-1">
          <div class="col-4 pb-3 text-sm" id="release-id">${matchingEvents[0].release_id}</div>
          <div class="col-4 pb-3 pl-2 text-info text-sm" id="status">${matchingEvents[0].release_status}</div>
          <div class="col-4 pb-3 text-sm" id="last-updated">${dayjs(matchingEvents[0].last_updated).format("MMM DD, YYYY")}</div>
        </div>
        <div class="row d-flex justify-content-between p-0 m-0 border-bottom px-1">
          <div class="col p-0 px-2 py-2">
            <div class="rounded p-1 d-block w-100 bg-gray text-sm">
              <span>🕒</span>
              ${dayjs(matchingEvents[0].planned_start_date).format("MMM DD, YYYY")} - ${dayjs(matchingEvents[0].planned_end_date).format("MMM DD, YYYY")}
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-between p-0 m-0 pt-3 pb-1 px-1">
          <div class="col-4 font-weight-bold text-s text-body-color"><strong>IMPACT</strong></div>
          <div class="col-8 pl-2 font-weight-bold text-s text-body-color"><strong>CATEGORY</strong></div>
        </div>
        <div class="row d-flex justify-content-between p-0 m-0 border-bottom px-1">
          <div class="col-4 pb-3" id="impact">${typeof getImpactBars(matchingEvents[0].relevance) === "string" ? getImpactBars(matchingEvents[0].relevance) : getImpactBars(matchingEvents[0].relevance).map((color) => `<div class="impact-bar" style="background-color: ${color};"></div>`).join("")}</div>
          <div class="col-8 pb-3 pl-2 text-sm" id="category">${matchingEvents[0].release_category}</div>
        </div>
      </div>
      <div class="tooltip-footer mt-4 px-2 text-start text-body-sm ${marginBottomClass}">
        <a class="text-decoration-none text-blue view-more-button cursor-pointer"><strong data-release-id="${matchingEvents[0].release_id}">🡢 View More</strong></a>
      </div>
      </div>
      ${matchingEvents.length > 1 ? `<nav aria-label="Page navigation" class="mt-2"><ul class="pagination justify-content-center pl-0 list-unstyled rounded">${createPaginationButtons(params, totalPages, 1)}</ul></nav>` : ""}
    </div>
  `;
}

function handlePaginationClick(index, matchingEvents) {
  const tooltipDetails = document.querySelector(".tooltip-animate");
  tooltipDetails.classList.add("tooltip-exit");
  tooltipDetails.addEventListener("animationend", function updateContent() {
    tooltipDetails.removeEventListener("animationend", updateContent);
    const event = matchingEvents[index];
    document.querySelector("#event-title strong").innerText = event.title;
    document.getElementById("release-id").innerText = event.release_id || "N/A";
    document.querySelector(".view-more-button strong").setAttribute("data-release-id", event.release_id);
    document.getElementById("status").innerText = event.release_status || "N/A";
    document.getElementById("last-updated").innerText = dayjs(event.last_updated).format("MMM DD, YYYY") || "N/A";
    const bars = getImpactBars(event.relevance);
    document.getElementById("impact").innerHTML = typeof bars === "string" ? bars : bars.map((color, index) => `<div class="impact-bar" style="background-color: ${color}; ${index > 0 ? "margin-left: 5px;" : ""}"></div>`).join("");
    document.getElementById("category").innerText = event.release_category || "N/A";
    document.getElementById("vendor-type").innerText = `Source | ${event.Vendor_type || "N/A"}`;
    document.getElementById("vendor-service").innerText = event.vendor_service || "N/A";
    tooltipDetails.classList.remove("tooltip-exit");
    tooltipDetails.classList.add("tooltip-enter");
    tooltipDetails.addEventListener("animationend", function removeEnterClass() {
      tooltipDetails.removeEventListener("animationend", removeEnterClass);
      tooltipDetails.classList.remove("tooltip-enter");
    }, { once: true });
  }, { once: true });
}

function setupPageButtonEvents(params, totalPages, handlePaginationClick, matchingEvents) {
  document.querySelectorAll(".page-link").forEach(btn => {
    btn.onclick = (event) => {
      const targetPage = event.target.getAttribute("data-page");
      if (targetPage) {
        const currentPage = parseInt(targetPage, 10);
        updatePagination(params, totalPages, handlePaginationClick, matchingEvents, currentPage);
        handlePaginationClick(currentPage - 1, matchingEvents);
      }
    };
  });
}

function updatePagination(params, totalPages, handlePaginationClick, matchingEvents, currentPage) {
  document.querySelector(".pagination").innerHTML = createPaginationButtons(params, totalPages, currentPage);
  setupPageButtonEvents(params, totalPages, handlePaginationClick, matchingEvents);
  setActivePageButton(params, currentPage);
}

export const loadingOption = {
  text: '',
  color: '#000000',
  maskColor: 'rgba(255, 255, 255, 0.4)',
  showSpinner: true,
  spinnerRadius: 20,
  lineWidth: 4,
};

export function getChartOption(events, uniqueServices, seriesData, startTime, endTime, isDataLoaded) {
  const noData = seriesData.length === 0;
  return {
    backgroundColor: "#FFFFFF",
    grid: { left: "2.5%", right: "0.5%", top: "10%", bottom: "17%", containLabel: true },
    tooltip: {
      trigger: "item", hideDelay: 300, enterable: true, confine: true, borderColor: "transparent", padding: 0, borderWidth: 0,
      formatter: function (params) {
        const matchingEvents = filterMatchingEvents(events, params);
        if (matchingEvents.length === 0) return "";
        const totalPages = matchingEvents.length;
        const htmlContent = createTooltipContent(params, totalPages, matchingEvents);
        requestAnimationFrame(() => setupPageButtonEvents(params, totalPages, handlePaginationClick, matchingEvents));
        return htmlContent;
      },
    },
    legend: {
      data: uniqueServices.filter(service => service !== 'Dummy Service'), type: "scroll", 
      orient: "horizontal", top: "91%", align: "left", width: "99%", pageIconColor: "#000", 
      pageIconInactiveColor: "#aaa", pageTextStyle: { color: "#000" }
    },
         
    xAxis: noData ? { show: false } : {
      type: "time", name: "Time", nameLocation: "end", nameGap: -100,
      nameTextStyle: { verticalAlign: "top", lineHeight: 70 }, splitLine: { show: false },
    },
    yAxis: noData ? { show: false } : {
      type: "value", name: "Number of Releases", nameLocation: "middle", nameRotate: 90, nameGap: 30, splitLine: { show: false }, minInterval: 1,
    },
    series: uniqueServices.map(service => ({
      name: service, type: "scatter",
      data: seriesData.filter(s => s.name === service),
      symbolSize: data => 8 + data[1] * 2.5,
      itemStyle: {
        borderColor: "#FFFFFF", borderWidth: 0.5,
        color: (params) => (params.data.isDummy ? 'rgba(0,0,0,0)' : params.color),
      },
    })),
    dataZoom: [{ type: "inside", xAxisIndex: [0], startValue: startTime.valueOf(), endValue: endTime.valueOf() }],
    graphic: noData ? [
      {
        type: 'text',
        left: 'center',
        top: 'center',
        style: {
          text: !isDataLoaded ? '' : 'No events found.',
          font: '16px Arial',
          fill: '#333'
        }
      }
    ] : []
  };
}

export function initializeChartEvents(myChart, setStartTime, setEndTime, initialStartTime, initialEndTime, openModal, setPickerStartTime, setPickerEndTime) {
  myChart.on("mouseover", (params) => {
    if (params.componentType === "series") {
      myChart.setOption({
        series: [{ markLine: { animationDuration: 200, symbol: "none", emphasis: { disabled: true }, data: [{ xAxis: params.value[0] }],
          label: { show: false }, tooltip: { show: false }, lineStyle: { color: "black" }, }, }],
      });
    }
  });

  myChart.on("dataZoom", () => {
    const { startValue, endValue } = myChart.getOption().dataZoom[0];
    setStartTime(dayjs(startValue));
    setEndTime(dayjs(endValue));
  });

  myChart.on("restore", () => {
    setStartTime(initialStartTime);
    setEndTime(initialEndTime);
    setPickerStartTime(initialStartTime);
    setPickerEndTime(initialEndTime);
  });

  myChart.on("showTip", () => {
    const tooltipDOM = document.querySelector(".chart-container > div:nth-child(2)");
    if (tooltipDOM) tooltipDOM.classList.add("tooltip-visible");
  });

  myChart.on("hideTip", () => {
    const tooltipDOM = document.querySelector(".chart-container > div:nth-child(2)");
    if (tooltipDOM) tooltipDOM.classList.remove("tooltip-visible");
  });

  myChart.on("finished", () => {
    const viewMoreButtons = document.querySelectorAll(".view-more-button");
    viewMoreButtons.forEach(button => {
      button.addEventListener("click", (e) => {
        const releaseId = e.target.getAttribute("data-release-id");
        openModal(releaseId);
      });
    });
  });


  let selectedSeries = new Set();

  myChart.on('legendselectchanged', function (params) {
      const seriesName = params.name;
      const option = myChart.getOption();
      const allSeries = option.series.map(series => series.name);
      const dummySeriesName = 'Dummy Service';
  
      if (selectedSeries.has(seriesName)) {

          selectedSeries.clear();
      } else {
          selectedSeries.add(seriesName);
      }
  
      const newSelected = allSeries.reduce((obj, seriesName) => {
          if (seriesName === dummySeriesName) {
              obj[seriesName] = true;
          } else {
              obj[seriesName] = selectedSeries.size === 0 || selectedSeries.has(seriesName);
          }
          return obj;
      }, {});
  
      myChart.setOption({
          legend: {
              selected: newSelected
          }
      });
  });
  
}
