import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import { redirectSSO } from "../Services";

export function Redirect() {
  const navigate = useNavigate();
  const location = useLocation();
  const code = location.state?.code;
  const redirectPath = sessionStorage.getItem("path");

  useEffect(() => {
    async function Redirect() {
      if (code) {
        let response = await redirectSSO(code);
        if (response.status === 200) {
          let location = response?.data?.location;
          let { email, token, username, isPilotUser } = response?.data;
          if (location) {
            setTimeout(() => {
              if (redirectPath) {
                window.location.href = `${location}/${redirectPath}`;
              } else {
                window.location.href = location;
              }
              if (email && token && username) {
                sessionStorage.setItem("email", email);
                sessionStorage.setItem("token", token);
                sessionStorage.setItem("username", username);
                sessionStorage.setItem("isLoggedIn", true);
                sessionStorage.setItem("isPilotUser", isPilotUser);
              }
            }, 1000);
          }
        }
        else if (response.status === 401) {
          navigate("/logout");
        } else {
          navigate("/error");
        }
      }
    }
    Redirect();
  }, [code]);

  const containerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  };

  const textStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#fff",
  };

  return (
    <Box style={containerStyle}>
      <Typography variant="h4" style={textStyle}>
        Logging In ...
      </Typography>
    </Box>
  );
}

