import React from 'react';
import './NotFound.css';
import Error from "../assets/images/Error.png"

export const NotFound = () => {
    return (
        <div className="not-found container">
            <h2>404 - Not Found</h2>
            <img
                src={Error}
                alt="404 Error"
                className="error-image"
            />
            <p>The page you're looking for does not exist.</p>
        </div>
    );
};

