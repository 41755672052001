import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import debounce from "lodash/debounce";
import { Alert, Autocomplete, Box, CircularProgress, Divider, Drawer, IconButton, Popover, TextField, Tooltip, Typography, Collapse } from "@mui/material";
import { BusinessCenter, Close, Square, WebAsset, OpenInNew, IosShare, ContentCopy, Send, InfoOutlined, Help } from "@mui/icons-material";
import "./EventDetails.css";
import { StyledRating } from "../../ListView/ListView";
import { getAdskEmpList, getReleaseDetails, shareRelease, toggleUpvote, getUserIdFromPreference } from "../../../Services";
import Loader from "../Loader/Loader";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { Warning } from '@mui/icons-material';
import { eventTypes } from "../../../utility/consts";

const EventDetails = ({ isDataLoaded, event, isOpen, setIsModalOpen }) => {
    const navigate = useNavigate();

    const [isDraweropen, setIsDrawerOpen] = useState(isOpen);
    const [isLoading, setLoader] = useState(true);
    const [details, setDetails] = useState({});
    const [history, setHistory] = useState();
    const [shareLink, setShareLink] = useState("");
    const [helperText, setHelperText] = useState("Copy Link");
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState([]);
    const [isSubmit, setSubmit] = useState(false);
    const [error, setError] = useState("");
    const [additionalData, setAdditionalData] = useState(null);
    const [upvoteCount, setUpvoteCount] = useState(0);
    const [hasUpvoted, setHasUpvoted] = useState(false);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const options = {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 2000,
        progress: undefined,
        theme: "light",
    };

    useEffect(() => {
        if (isOpen && event) {
            fetchUpvoteData(event["release_id"], event["vendor_service"]);
        }
    }, [event, isOpen]);
    
    const fetchUpvoteData = async (releaseId, service) => {
        try {
            const response =  await getReleaseDetails(releaseId, service);
    
            if (response && response.data) {
                const responseData = response.data;
                const upvoteData = responseData.upvote_data;
                const userId = String(getUserIdFromPreference());
                setUpvoteCount(upvoteData.upvote_count);
                setHasUpvoted(upvoteData.upvoted_by.includes(userId));
            }
        } catch (error) {
            toast.error("Failed to fetch upvote data!", options);
        }
    };
    
    const handleUpvoteClick = debounce(async () => {
        const updatedHasUpvoted = !hasUpvoted;
        const updatedUpvoteCount = updatedHasUpvoted ? upvoteCount + 1 : upvoteCount - 1;
    
        setHasUpvoted(updatedHasUpvoted);
        setUpvoteCount(updatedUpvoteCount);
    
        try {
            const body = { release_id: event.release_id, user_id: String(getUserIdFromPreference()) };
            const response = await toggleUpvote(body);
    
            if (!response || !response.data.success) {
                setHasUpvoted(!updatedHasUpvoted);
                setUpvoteCount(updatedHasUpvoted ? updatedUpvoteCount - 1 : updatedUpvoteCount + 1);
                toast.error("Failed to toggle upvote!", options);
            } else {
                toast.success(response.data.message, options);
            }
        } catch (error) {
            console.log('Error toggling upvote:', error);
            setHasUpvoted(!updatedHasUpvoted);
            setUpvoteCount(updatedHasUpvoted ? updatedUpvoteCount - 1 : updatedUpvoteCount + 1);
            toast.error("Failed to toggle upvote!", options);
        }
    }, 400);
    
    
    useEffect(() => {
        setIsDrawerOpen(isOpen);
        if (isOpen && event) {
            if (event["additional_details"]) {
                setAdditionalData(JSON.parse(event["additional_details"]));
            }
            window.history.pushState(null, "", `/${event.release_id}`);
            setLoader(true);
            getReleaseDetailsForEvent(event["release_id"], event["vendor_service"]);
        }
    }, [event, isOpen]);

    const toggleDrawer = (event, bool) => {
        setIsDrawerOpen(bool);
        setIsModalOpen(event, bool);
        if (!bool) {
            setDetails({});
            setHistory(null);
        }
    };

    const getReleaseDetailsForEvent = async (id, service) => {
        try {
            const response = await getReleaseDetails(id, service);
            if (response && response.data) {
                let data = response.data;
                setDetails(data);
                if (data["history"]) {
                    // Sorting the release history based on dates in descending order.
                    const dataArray = Object.entries(data["history"]);
                    dataArray.sort((a, b) => new Date(b[1]["updatedAt"]) - new Date(a[1]["updatedAt"]));
                    const sortedData = Object.fromEntries(dataArray);
                    setHistory(sortedData);
                }
            } else {
                toast.error("Error while getting release details!!", options);
            }
            setLoader(false);
        } catch (error) {
            console.log(error?.message);
            setLoader(false);
            if (
                error?.response?.status === 401 ||
                error?.response?.data?.message === "User authorization failed."
            ) {
                navigate("/logout");
            } else {
                navigate("/error");
            }
        }
    };

    const getEmpData = async (searchStr) => {
        try {
            const response = await getAdskEmpList(searchStr);
            if (response.status === 200 && response && response.data) {
                setUsers(response.data.users);
                setLoading(false)
            }

        } catch (error) {
            console.log(error?.message);
            if (
                error?.response?.status === 401 ||
                error?.response?.data?.message === "User authorization failed."
            ){
             navigate("/logout");
            } else {
                toast.error("Error while getting Employee List!!", options);
                navigate("/error");
            }
            setLoading(false)
        }
    };

    const sendQuery = useCallback((value) => {
        getEmpData(value);
    }, []);

    const debouncedSendQuery = useMemo(() => {
        return debounce(sendQuery, 1500);
    }, [sendQuery]);

    const handleInputChange = (e) => {
        const { value } = e.target;
        if (value && value.length >= 3) {
            setLoading(true)
            debouncedSendQuery(value);
        }
    };

    const handleEmployeeData = (options, value) => {
        setFormData(value);
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(shareLink);
        setHelperText('Link Copied!');
    };

    const shareReleaseDetails = () => {
        setSubmit(true);
    }

    useEffect(() => {
        if (isSubmit) {
            console.log(isSubmit);
            if (Object.keys(formData).length > 0) {
                setError("");
                closePopover();
                const empList = formData.map(e => e["email"]);
                shareReleaseData(empList);
            } else {
                setError("Employee Name is Required!")
            }
            setSubmit(false);
        }
    }, [isSubmit]);

    useEffect(() => {
        setShareLink(`${window.location.origin}/${event.release_id}`);
    }, [event?.release_id]);

    const openPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closePopover = () => {
        setAnchorEl(null);
        setFormData([]);
    };

    const shareReleaseData = async (emp) => {
        try {
            const email = sessionStorage.getItem('email');
            const name = sessionStorage.getItem('username');
            const body = {
                'by': email,
                'name': name,
                'to': emp,
                'release_id': event?.release_id,
                'title': event?.title,
                'service': event?.vendor_service,
                'release_link': shareLink
            }
            const response = await shareRelease(body);
            if (response.status === 200 && response?.data) {
                toast.success("Release shared successfully!", options);
            }

        } catch (error) {
            console.log(error);
            toast.error("Error while sending the release details!!", options);
        }
    };

    const renderAdditionalData = () => {
        const getStatusIcon = (plannedStartDate, status) => {
            const startDate = new Date(plannedStartDate);
            if (isNaN(startDate)) return "N/A";
    
            const isCancelled = status === 'CANCELLED';
            const isExpiringSoon = new Date() >= startDate;
            return (
                    <div className="d-flex align-items-center">
                        { (isCancelled || isExpiringSoon) && (
                            <Warning 
                                className={`mr-1 ${isCancelled ? "text-danger" : "text-warning"}`}
                                fontSize="small" 
                            />
                        )}
                        <span className={`text-s ${isCancelled ? "text-danger" : isExpiringSoon ? "text-black" : ""}`}>
                            {isCancelled ? "Expired" : isExpiringSoon ? "Expiring Soon" : "Open"}
                        </span>
                    </div>
            );
        };
    
        return (
            additionalData && Object.entries(additionalData).map(([key, value]) => (
                <div className="col-6 mb-3" key={key}>
                    <p className="mb-0 text-sm text-capitalize">
                        <strong>{key}</strong>
                    </p>
                    {value.length === 19 && !isNaN(new Date(value)) ? (
                        <span className="text-s">{moment(value).format("DD MMM, YYYY  HH:MM:SS")}</span>
                    ) : key.toLowerCase() === 'rfc_details' && value.length > 3 ? (
                        <div>
                            {value.split(', ').map((rfc) => (
                                <a
                                    className="text-s d-flex align-items-center"
                                    key={rfc}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://autodesk.service-now.com/nav_to.do?uri=task.do?sysparm_query=number=${rfc}`}
                                >
                                    {rfc} <OpenInNew className="text-blue ml-1" fontSize="xs" />
                                </a>
                            ))}
                        </div>
                    ) : key.toLowerCase() === 'status' ? (
                        getStatusIcon(event.planned_start_date, value)
                    ) : (
                        <span className="text-s">{value || "N/A"}</span>
                    )}
                </div>
            ))
        );
    };    

    return (
        isOpen &&
        (isLoading ? (
            <Loader />
        ) : (
            <Drawer
                anchor="right"
                open={isDraweropen}
                onClose={() => {
                    toggleDrawer(null, false);
                    window.history.pushState(null, "", "/");
                }}
            >
                <div className="d-flex justify-content-between align-items-start">
                    <span className="top-tag">{event.vendor_service}</span>
                    <div className="d-flex align-items-center">
                        <p className="py-1 px-2 my-2 text-s d-flex align-items-center">
                            <Tooltip className="mr-2" title={'You have selected this business service in your preference which has this as its service component or dependent service in service registry.'}>
                                <Help sx={{ fontSize: 15 }} />
                            </Tooltip>
                            <strong>Why am I seeing this?</strong>
                        </p>
                        <IconButton
                            className="p-1 mx-2"
                            onClick={() => {
                                toggleDrawer(null, false);
                                window.history.pushState(null, "", "/");
                            }}
                        >
                            <Close />
                        </IconButton>
                    </div>
                </div>
                <Box sx={{ width: 700, pl: 4, pr: 3, fontSize: "13px" }}>
                    <div className="mb-3">
                        <div className="text-secondary d-flex text-s">
                            <span className="">{event.release_id}</span>
                            <h2 className="dot-content mx-2">&#183;</h2>
                            <span className="mr-1">Status: </span>
                            <span
                                className={
                                    event.release_status.toUpperCase() === "COMPLETED"
                                        ? "text-success"
                                        : event.release_status.toUpperCase() === "PLANNED"
                                            ? "text-secondary"
                                            : "text-info"
                                }
                            >
                                {event.release_status}
                            </span>
                            <h2 className=" dot-content mx-2">&#183;</h2>
                            <span className="">
                                {" "}
                                Last updated on{" "}
                                {new Date(event.last_updated) != "Invalid Date"
                                    ? new Date(event.last_updated).toDateString().slice(4, 16)
                                    : "N/A"}
                            </span>
                        </div>
                        <h5 className="font-weight-bold mb-2">{event.title}</h5>
                        <div className="d-flex align-items-center text-sm">
                            <a className="d-flex align-items-center text-primary cursor-pointer"
                                onClick={openPopover}
                                aria-describedby={id}
                                rel="noreferrer">
                                <IosShare className="text-blue mr-1" fontSize="xs" />
                                Share
                            </a>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={closePopover}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <div className="share d-flex align-items-center px-3 py-2">
                                    <Autocomplete
                                        multiple
                                        id="employee"
                                        freeSolo
                                        className="w-350"
                                        loading={loading}
                                        getOptionLabel={(option) => option.displayName}
                                        filterOptions={(x) => x}
                                        onChange={(event, value) => handleEmployeeData(event, value)}
                                        options={users}
                                        key={option => option.email}
                                        renderOption={(props, option) => (
                                            <Typography title={option.email} sx={{ fontSize: '0.9rem' }} {...props}>
                                                {option.displayName}
                                            </Typography>)
                                        }
                                        renderInput={(params) => <TextField
                                            {...params}
                                            autoFocus
                                            margin="dense"
                                            id="name"
                                            width="200"
                                            label="Enter Autodesk Email/Username"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            }}
                                            required
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            name='employee'
                                            error={error.length > 0 && formData.length <= 0}
                                            helperText={error.length > 0 && formData.length <= 0 ? error : ""}
                                            value={formData || ''}
                                            onChange={handleInputChange}
                                        />}
                                    />
                                    <Tooltip title="Send" arrow>
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-outline-secondary active mx-2 mt-4"
                                            onClick={shareReleaseDetails}>
                                            <Send fontSize="xs" />
                                        </button>
                                    </Tooltip>
                                    <Tooltip title={helperText} arrow>
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-outline-secondary mt-4"
                                            onClick={handleCopyClick}>
                                            <ContentCopy fontSize="xs" />
                                        </button>
                                    </Tooltip>
                                </div>
                            </Popover>
                            <div className="d-flex align-items-center ml-4">
                                {event.Vendor_type === "Microsoft 365" ||
                                    event.release_url === "N/A" ||
                                    event.release_url === "NA" ? (
                                    <Tooltip title="Release details not available" arrow>
                                        <a className="text-secondary" rel="noreferrer">
                                            <OpenInNew disabled sx={{ color: 'grey !important', pointerEvents: 'none' }} className="text-blue mr-1" fontSize="xs" />
                                            View Details
                                        </a>
                                    </Tooltip>
                                ) : (
                                    <a
                                        href={event.release_url ? event.release_url : 'https://autodesk.service-now.com/nav_to.do?uri=task.do?sysparm_query=number=' + event.release_id}
                                        target="_blank"
                                        className="d-flex align-items-center text-primary cursor-pointer"
                                        rel="noreferrer"
                                    >
                                        <OpenInNew className="text-blue mr-1" fontSize="xs" />
                                        View Details
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                    <Alert 
                        severity="info" 
                        icon={false} 
                        action={
                            <Box className="d-flex justify-content-center align-items-center position-relative px-2">
                                <Typography 
                                    variant="body2" 
                                    className="text-xs d-flex align-items-center mr-2 text-muted">
                                    {upvoteCount > 0 && (
                                        hasUpvoted 
                                            ? upvoteCount === 1 
                                                ? 'Marked helpful by you' 
                                                : upvoteCount === 2 
                                                    ? 'Marked helpful by you and 1 other' 
                                                    : `Marked helpful by you and ${upvoteCount - 1} others`
                                            : upvoteCount === 1 
                                                ? 'Marked helpful by 1 other' 
                                                : `Marked helpful by ${upvoteCount} others`
                                    )}
                                </Typography>
                            </Box>
                        } 
                        className="alert d-flex align-items-center px-2"
                        sx={{ 
                            bgcolor: '#F7F6FB', 
                            height: 40, 
                            '.MuiAlert-action': { p: 0 }, 
                            '.MuiAlert-message': { display: 'contents' }
                        }}>                        
                        <Typography 
                            variant="body2" 
                            className="text-s text-body-color" >
                            Was this data helpful?
                        </Typography>
                        <IconButton 
                            aria-label="thumbs up" 
                            className="ml-3 px-1 position-relative rounded-circle" 
                            sx={{ 
                                backgroundColor: hasUpvoted ? 'black' : 'gray', 
                                color: 'white', 
                                '&:hover': { backgroundColor: hasUpvoted ? 'dimgray' : 'lightgray' },
                                width: '20px',
                                height: '20px',
                            }} 
                            onClick={handleUpvoteClick}>
                            <ThumbUpOffAltIcon className="text-sm"/>
                        </IconButton>
                    </Alert>
                    <div className="row mt-3">
                        <div className="col-6 mb-3">
                            <p className="mb-1 text-sm">
                                <strong>Business Service</strong>
                            </p>
                            <div className="d-flex align-items-start">
                                <WebAsset fontSize="small" className="mr-1" />
                                <span className="text-s">{event.adsk_business_service}</span>
                            </div>
                        </div>
                        <div className="col-6 mb-3">
                            <p className="mb-1 text-sm">
                                <strong>
                                    {event.Vendor_type === "AWS Health" 
                                    ? "Affected Resources" 
                                    : "Impacted Vendor Service"}
                                </strong>
                            </p>
                            <div className="d-flex align-items-start">
                                <WebAsset fontSize="small" className="mr-1" />
                                <span className="text-s-nowrap">{event.impacted_services}</span>
                            </div>
                        </div>
                        <div className="col-6 mb-3">
                            <p className="mb-1 text-sm">
                                <strong>Source</strong>
                            </p>
                            <div className="d-flex align-items-center">
                                <BusinessCenter fontSize="small" className="mr-1" />
                                <span className="text-s">{event.Vendor_type}</span>
                            </div>
                        </div>
                        <div className="col-6 mb-3">
                            <p className="mb-1 text-sm d-flex align-items-center">
                                <strong>Category</strong>
                                <Tooltip className="ml-1" title={'This value is determined based on the data available. It is advised to validate this once manually.'}>
                                    <InfoOutlined sx={{ fontSize: 15 }} />
                                </Tooltip>
                            </p>
                            <span className="text-s">{event.release_category || "N/A"}</span>
                        </div>
                        <div className="col-6 mb-3">
                            <p className="mb-1 text-sm">
                                <strong>Start Date</strong>
                            </p>
                            <span className="text-s">
                                {new Date(event.planned_start_date) != "Invalid Date"
                                    ? new Date(event.planned_start_date)
                                        .toDateString()
                                        .slice(4, 16)
                                    : "N/A"}
                            </span>
                        </div>
                        <div className="col-6 mb-3">
                            <p className="mb-1 text-sm">
                                <strong>
                                    {
                                    (["On-Prem DB"].includes(event.Vendor_type) ||  
                                    (event.Vendor_type === "AWS Health" && event.title?.startsWith("ACM Certificate"))) 
                                    ? "Expiry Date" 
                                    : ["Firewall/Security Exception"].includes(event.Vendor_type) 
                                    ? "Due Date" : "End Date"
                                    }
                                </strong>
                            </p>
                            <span className={`text-s`}>
                                {new Date(event.planned_end_date) != "Invalid Date"
                                    ? new Date(event.planned_end_date).toDateString().slice(4, 16)
                                    : "N/A"}
                            </span>
                        </div>
                        <div className="col-6 mb-3">
                            <p className="mb-1 text-sm">
                                <strong>Act By</strong>
                            </p>
                            <span className={`text-s`}>
                                {new Date(event.act_by) != "Invalid Date"
                                    ? new Date(event.act_by)
                                        .toDateString()
                                        .slice(4, 16)
                                    : "N/A"}
                            </span>
                        </div>
                        {
                            ["On-Prem DB"].includes(event.Vendor_type) ? renderAdditionalData() : null
                        }
                        <div className="col-6 mb-3">
                            <p className="mb-1 text-sm d-flex align-items-center">
                                <strong>Impact</strong>
                                <Tooltip className="ml-1" title={'This value is determined based on the data available. It is advised to validate this once manually.'}>
                                    <InfoOutlined sx={{ fontSize: 15 }} />
                                </Tooltip>
                            </p>
                            {event.relevance ? (
                                <div className="d-flex align-items-center">
                                    <StyledRating
                                        name="customized-color"
                                        defaultValue={
                                            event.relevance
                                                ? event.relevance.toLowerCase() === "low"
                                                    ? 1
                                                    : event.relevance.toLowerCase() === "medium"
                                                        ? 2
                                                        : 3
                                                : 0
                                        }
                                        max={3}
                                        disabled
                                        icon={<Square sx={{ fontSize: "16px" }} />}
                                        emptyIcon={<Square sx={{ fontSize: "16px" }} />}
                                    />
                                    <span className="ml-1 text-s text-capitalize">
                                        {event.relevance}
                                    </span>
                                </div>
                            ) : (
                                <span className="text-s">N/A</span>
                            )}
                        </div>
                        <div className="col-6 mb-3">
                            <p className="mb-0 text-sm">
                                <strong>Environment</strong>
                            </p>
                            <span className="text-s">{event.environment || "N/A"}</span>
                        </div>
                        <div className="col-6 mb-3">
                            <p className="mb-0 text-sm">
                                <strong>Platform</strong>
                            </p>
                            <span className="text-s">{event.platform || "N/A"}</span>
                        </div>
                        <div className="col-6 mb-3">
                            <p className="mb-0 text-sm">
                                <strong>Geo</strong>
                            </p>
                            <span className="text-s">{event.geo || "N/A"}</span>
                        </div>
                        <div className="col-6 mb-3">
                            <p className="mb-0 text-sm">
                                <strong>What Action Needed</strong>
                            </p>
                            <span className="text-s">{event.what_action_needed || "N/A"}</span>
                        </div>
                        {
                            !["On-Prem DB"].includes(event.Vendor_type) ? renderAdditionalData() : null
                        }
                    </div>
                    <Divider />
                    <div className="mt-3">
                        <div className="mb-4">
                            <p className="text-body-1 mb-2">
                                <strong>Summary</strong>
                            </p>
                            <div
                                className=""
                                dangerouslySetInnerHTML={{ __html: event.feature_delivered }}
                            ></div>
                        </div>
                        {history && (
                            <div>
                                <Divider />
                                <div className="d-flex justify-content-between mt-3">
                                    <p className="text-body-1 mb-3">
                                        <strong>Updates</strong>
                                    </p>
                                </div>
                                {Object.keys(history).map((key) => (
                                    <div
                                        className="border-left border-4 border-info pl-3"
                                        key={key}
                                    >
                                        <div className="d-flex justify-content-between align-items-center">
                                            <p className="badge badge-secondary text-capitalize mb-1 text-xs">
                                                {key}
                                            </p>
                                            <p className="text-sm mb-1">
                                                <strong>
                                                    {moment(history[key]["updatedAt"]).format(
                                                        "DD MMM, YYYY  HH:MM:SS"
                                                    )}
                                                </strong>
                                            </p>
                                        </div>
                                        <p className="mb-4 text-sm">{history[key]["message"]}</p>
                                    </div>
                                ))}
                                <div className="d-flex align-items-center justify-content-around overflow-hidden mb-3">
                                    <Divider className="col-5" />
                                    <small className="mx-3 text-nowrap">End of Updates</small>
                                    <Divider className="col-5" />
                                </div>
                            </div>
                        )}
                    </div>
                </Box>
            </Drawer >
        ))
    );
};

export default EventDetails;
