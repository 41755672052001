export const environment = "prod";

let apiUrl;
if (environment === "local" || environment === "dev") {
  apiUrl = "https://backend.release-calendar-dev.autodesk.com/api/erc";
} else if (environment === "prod"  || environment === "preprod") {
  apiUrl = "https://backend.release-calendar.autodesk.com/api/erc";
}

export const REACT_APP_API = apiUrl;
export const REACT_APP_LOCAL_USER_EMAIL = '';
export const REACT_APP_LOCAL_USER_NAME = '';
let token = "";

if (environment === "local") {
  sessionStorage.setItem("email", REACT_APP_LOCAL_USER_EMAIL);
  sessionStorage.setItem("username", REACT_APP_LOCAL_USER_NAME);
  sessionStorage.setItem("isLoggedIn", true);
  sessionStorage.setItem("token", token);
}