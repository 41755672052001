export const vendorDict = {
    "SaaS Vendor": "saas",
    "BCR": "bcr",
    "BFR": "bfr",
    "EFR": "efr",
    "EMR": "emr",
    "ODR": "odr",
    "ServiceNow RFCs": "snow",
    "On-Prem DB": "dbpswd",
    "Microsoft 365": "365",
    "Firewall/Security Exception": "fwexc",
    "AWS Health":"awshealth"
};

export const sourceMapping = {
    "saas vendor": "SaaS Vendor",
    "bcr": "Business Critical",
    "bfr": "Business Flexible",
    "efr": "Enterprise Flexible",
    "emr": "Enterprise Maintenance",
    "odr": "On Demand",
    "servicenow rfcs": "ServiceNow RFCs",
    "on-prem db": "On-Prem DB",
    "microsoft 365": "Microsoft 365",
    "firewall/security exception": "Firewall/Security Exception",
    "aws health":"AWS Health"
}

export const eventTypes = [
    "Firewall/Security Exception",
    "On-Prem DB",
    "AWS Health",
  ];
  
