// Notifications.js
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import { Tooltip } from '@mui/material';

const Notifications = ({ open, onClose, notifications }) => {
    const [notificationList, setNotificationList] = useState(notifications);
    useEffect(() => {
        setNotificationList(notifications);
    }, [notifications]);

    const markAsRead = (notification) => {
        const updatedNotificationList = notificationList.map((n) =>
            n.id === notification.id ? { ...n, read: true } : n
        );
        setNotificationList(updatedNotificationList);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Notifications</DialogTitle>
            <DialogContent>
                <div className='pb-3'>
                    <div className='mt-2 mb-4'>
                        <div className='card-body'>
                            <Alert severity="info">We will be live soon with Personalized Notifications. Stay Tuned!</Alert>
                        </div>
                    </div>
                </div>
                {/* <List>
                    {notificationList.map((notification) => (
                        <ListItem sx={{ display: 'flex', alignItems: 'flex-start' }} key={notification.id}>
                            <ListItemText
                                primary={
                                    notification.read ? (
                                        notification.title
                                    ) : (
                                        <strong>{notification.title}</strong>
                                    )
                                }
                                secondary={notification.timestamp}
                            />
                            {notification?.read}
                            <Button
                                variant="text"
                                size="small"
                                sx={{ fontSize: 10 }}
                                onClick={() => {
                                    markAsRead(notification);
                                    notification.read = true;
                                }}
                            >
                                <Tooltip title='Mark as Read' >
                                    {!notification.read ? <MarkChatReadIcon /> : <MarkChatReadIcon sx={{ color: 'darkgray' }} />}
                                </Tooltip>
                            </Button>
                        </ListItem>
                    ))}
                </List> */}
            </DialogContent>
        </Dialog>
    );
};

export default Notifications;
