import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";

import ListView from "../ListView/ListView";
import { getSubscribedReleases, getSubscriptionData, getActiveSources } from "../../Services";
import Loader from "../Common/Loader/Loader";
import { createColorCodeForRelease, vendorDict } from "../../utility";

export const SubscribedReleases = ({ preference }) => {
  const navigate = useNavigate();
  const [isLoading, setLoader] = useState(true);
  const [filtererdEvents, setFilteredEvents] = useState([]);
  const [monthlyDigest, setDigest] = useState(false);
  const loggedIn =
    sessionStorage.getItem("isLoggedIn") === "true" ? true : false;

  useEffect(() => {
    const pathname = window.location.pathname;
    setLoader(true);

    if (loggedIn) {
      sessionStorage.removeItem("path");
    } else {
      let path = "";

      if (pathname === "/subscribed-releases") {
        path = "subscribed-releases";
      } else if (pathname === "/subscribed-monthly-releases") {
        path = "subscribed-monthly-releases";
      }

      if (path) {
        sessionStorage.setItem("path", path);
        sessionStorage.setItem("directLogin", true);
        navigate("/login");
      }
    }
  }, [loggedIn]);

  useEffect(() => {
    if (preference) {
      getSubscriptionInitData(preference["id"]);
    }
  }, [preference]);

  const getSubscriptionInitData = async (id) => {
    try {
      const [resp1, resp2] = await Promise.allSettled([getSubscriptionData(id), getActiveSources()]);
      if (resp1.value.data && resp2.value.data) {
        getSourceData(resp1.value.data, resp2.value.data);
      }
      else {
        navigate("/error");
      }
    } catch (error) {
      if (
        error?.response?.status === 401 ||
        error?.response?.data?.message === "User authorization failed."
      ) {
        navigate("/logout");
      } else {
        navigate("/error");
      }
    }
  }

  const getSourceData = async (subs, sources) => {
    try {
      let data = [];
      let body = createSubsPayload(subs);
      for (let releaseType of sources['releaseType']) {
        if (releaseType && subs[vendorDict[releaseType]]) {
          body['releaseType'] = releaseType;
          body['services'] = subs[vendorDict[releaseType]];
          const response = await getSubscribedReleases(body);
          if (response && response.data) {
            data = [...data, ...response.data];
            if (data) {
              createColorCodeForRelease(data);
              setFilteredEvents(data);
            }
          } else {
            navigate("/error");
          }
        }
      }

    } catch (error) {
      const err = error?.response;
      if (
        err?.message === "User authorization failed." ||
        err?.status === 401
      ) {
        const msg = err.data;
        if (msg && msg?.error) {
          setDigest(true);
        } else navigate("/logout");
      } else {
        navigate("/error");
      }
    }
    setLoader(false);
  };

  const createSubsPayload = (subs) => {
    let isMonthly;
    if (window.location.pathname === "/subscribed-monthly-releases") {
      isMonthly = true;
    } else if (window.location.pathname === "/subscribed-releases") {
      isMonthly = false;
    }
    return {
      'isMonthly': subs["monthly_digest"],
      'isMonthView': isMonthly,
      'category': isMonthly ? [] : getCategoriesSelected(subs),
    };
  }

  const getCategoriesSelected = (subs) => {
    let category = [];
    if (subs['release']) category.push('Release');
    if (subs['maintenance']) category.push('Maintenance');
    if (subs['outage']) category.push('Outage');
    return category;
  }

  const backToHome = () => {
    navigate("/");
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="min-h-list m-4">
      <div className="d-flex justify-content-between align-items-center">
        <h3>Updates for Subscribed Releases</h3>
        <Button onClick={backToHome}>
          <ArrowBack className="text-body mr-2" />
          Back to Home
        </Button>
      </div>
      {
        !monthlyDigest ?
          <ListView events={filtererdEvents} /> :
          <Alert severity="warning" className=''>
            You have not subscribed for notifications. Please navigate to Preferences to update your settings.
          </Alert>
      }

    </div>
  );
};
