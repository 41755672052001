import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SentimentDissatisfied, SentimentNeutral } from "@mui/icons-material";

import Loader from "../../Common/Loader/Loader";
import { unsubscribeSubscriptions } from "../../../Services";
import { toast } from "react-toastify";

export const UnsubscribeView = () => {
    const navigate = useNavigate();
    const [isSubmitted, setSubmitted] = useState(false);
    const [open, setOpen] = useState(false);
    const [isLoading, setLoader] = useState(true);
    const loggedIn = sessionStorage.getItem("isLoggedIn") === 'true' ? true : false;

    const options = {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 2000,
        progress: undefined,
        theme: "light",
    };

    useEffect(() => {
        if (loggedIn) {
            setLoader(false);
            setOpen(true);
        } else {
            sessionStorage.setItem("path", 'unsubscribe');
            sessionStorage.setItem("directLogin", true);
            setLoader(true);
            navigate('/login');
        }
    }, [open])

    const onClose = () => {
        sessionStorage.removeItem("path");
        navigate('/');
    };

    const saveChanges = async () => {
        setLoader(true);
        sessionStorage.removeItem("path");
        try {
            const id = JSON.parse(sessionStorage.getItem('preference'))['id'];
            const response = await unsubscribeSubscriptions({ id: id });
            if (response.status === 200) {
                setSubmitted(true);
            } else {
                toast.error("Error while updating subscriptions!", options);
            }
            setLoader(false);
        } catch (error) {
            console.log(error?.message);
            setLoader(false);
            if (
                error?.response?.status === 401 ||
                error?.response?.data?.message === "User authorization failed."
            ) {
                navigate("/logout");
            } else {
                navigate("/error");
            }
        }
    }

    return isLoading ?
        <Loader /> :
        <div>
            <Box sx={{ minHeight: 700, height: '100%' }}>
                <Dialog className='preference' open={open} onClose={onClose} fullWidth maxWidth="sm">
                    <Box sx={{ bgcolor: 'background.paper' }}>
                        {
                            !isSubmitted ? (
                                <>
                                    <DialogTitle>Unsubscribe from Enterprise Application Updates</DialogTitle>
                                    <DialogContent>
                                        <div className="text-center d-flex align-items-center">
                                            <SentimentNeutral className="text-warning" fontSize="large" />
                                            <span className="ml-2">
                                                If you unsubscribe, you will stop receiving all the notifications from us.
                                            </span>
                                        </div>
                                    </DialogContent>
                                    <DialogActions className='py-3 px-4 justify-content-center'>
                                        <Button onClick={saveChanges} variant="contained">Unsubscribe</Button>
                                        <Button onClick={onClose} variant="outlined">Cancel</Button>
                                    </DialogActions>
                                </>
                            ) : <>
                                <DialogTitle>We are sad to see you go!</DialogTitle>
                                <DialogContent>
                                    <div className="text-center d-flex align-items-center">
                                        <SentimentDissatisfied className="text-warning" fontSize="large" />
                                        <span className="ml-2">
                                            You have been unsubscribed and will no longer hear from us.
                                        </span>
                                    </div>
                                </DialogContent>
                                <DialogActions className='py-3 px-4 justify-content-center'>
                                    <Button onClick={onClose} variant="contained">Go To Home</Button>
                                </DialogActions>
                            </>
                        }
                    </Box>
                </Dialog>
            </Box>
        </div >;
};
