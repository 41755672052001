import axios from "axios";
import { REACT_APP_API, environment } from "../environment";

const getTokenFromStorage = () => {
  return sessionStorage.getItem("token");
};
const token = getTokenFromStorage();

let headers = {
  "Content-Type": "application/json",
  "Authorization": token,
};

const route = environment === "preprod" ? '/preprod' : '';

export const loginSSO = () => {
  return axios.get(`${REACT_APP_API}/sso_login${route}`);
}

export const redirectSSO = (code) => {
  return axios.post(`${REACT_APP_API}/sso_redirect${route}`, { data: code });
}