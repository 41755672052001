import React, { useState } from 'react';
import "./Footer.css";

const Footer = () => {
  const [minimized, setMinimized] = useState(false);
  const date = new Date().getFullYear();

  const handleMinimize = () => {
    setMinimized(!minimized);
  };

  const getTokenFromStorage = () => {
    return sessionStorage.getItem("token");
  };

  const token = getTokenFromStorage();

  return token ? (
    <div className="">
      <footer
        onClick={handleMinimize}
        className={`container-fluid text-center text-white ${minimized ? "minimized" : ""
          }`}
      >
        {!minimized && (
          <>
            {/* <span id="permission-message" onClick={handleMinimize}>
              Note: If you are being redirected to login page, you may not have
              access to autobots portal. Please reach out to{" "}
              <a className='text-success' href="https://autodesk.slack.com/archives/CMSKD6FJL" target='_blank'>
                <strong> #edc-autobots-support </strong>
              </a>{" "}
              slack channel to get access.
            </span> */}
            <p className="py-2 mb-0 text-body-1">
              Copyright Autodesk, Inc. {date} | All rights reserved.
            </p>
          </>
        )}
      </footer>
    </div>
  ) : '';
};

export default Footer;