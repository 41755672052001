import accentColors from "../assets/color.json";

export const createColorCodeForRelease = (data) => {
    try {
        data.map((d) => {
            //create release color coding with accent colors
            d["title"] = d["release_title"];
            d["color"] = accentColors[d["Vendor_type"].toLowerCase()];
        });
        return accentColors;
    } catch (error) {
      console.error("Error while creating color coding for releases:", error);
    }
  };
  