import React from 'react';
import { Button, CssBaseline, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LoginIcon from '@mui/icons-material/Login';
import { clearStorageData } from '../../utility';

const StyledTypography = styled(Typography)({
  marginBottom: (theme) => theme.spacing(4),
  textAlign: 'center',
});

const LogoutPage = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    clearStorageData();
    navigate('/login');
  };

  return (
    <div className="logout-container">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width:'100%',
          transform: 'translateY(-7%)'
        }}
      >
        <Paper
          elevation={5}
          square={false}
          sx={{
            padding:2.5,
            width:'90%',
            maxWidth:'430px',
            textAlign:'center'
          }}
          className="logout-container-paper"
        >
          <CssBaseline />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <StyledTypography color={'white'} variant="h5" style={{ fontSize: '1.2rem' }}>
              Your Session has Expired.
            </StyledTypography>
            <StyledTypography color={'white'} variant="h6">
              Please login again to continue.
            </StyledTypography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLoginRedirect}
              endIcon={<LoginIcon />}
            >
              Go to Login
            </Button>
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default LogoutPage;
