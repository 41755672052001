import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import MainView from "./components/Main/MainView";
import LoginPage from "./components/Login/Login";
import Header from "./components/Common/Header/Header";
import Footer from "./components/Common/Footer/Footer";
import ErrorPage from "./components/Error/Error";
import LogoutPage from "./components/Logout/Logout";
import { NotFound, Redirect } from "./utility";
import * as C from "./components";

function App() {
  const [preference, setPreference] = useState(null);
  const setPreferences = (preferences) => {
    setPreference(preferences);
  };

  return (
    <BrowserRouter>
      <div>
        <Header setPreferences={setPreferences} />
        <Routes>
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/redirect" element={<Redirect />} />
          <Route exact path="/unsubscribe" element={<C.UnsubscribeView />} />
          <Route
            exact
            path="/subscribed-releases"
            element={<C.SubscribedReleases preference={preference} />}
          />
          <Route
            exact
            path="/subscribed-monthly-releases"
            element={<C.SubscribedReleases preference={preference} />}
          />
          <Route
            exact
            path="/"
            element={<MainView preference={preference} />}
          />
          <Route
            path="/:releaseId"
            element={<MainView preference={preference} />}
          />
          <Route path="*" element={<NotFound />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/logout" element={<LogoutPage />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
