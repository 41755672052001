// Notifications.js
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    DialogActions,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    OutlinedInput,
    RadioGroup,
    Select,
    Switch,
    Tab,
    Tabs,
    Tooltip,
    styled,
} from "@mui/material";
import { ExpandMore, InfoOutlined, Lightbulb } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import timezones from 'timezones-list';
import Alert from '@mui/material/Alert';
import { toast } from 'react-toastify';

import './UserPreference.css';
import { getParentServices, getSubscriptionData, updateSubscriptions, updateUserPreferences } from '../../Services';
import { useNavigate } from 'react-router-dom'
import moment from 'moment-timezone';
import Loader from '../Common/Loader/Loader';
import { vendorDict, eventTypes } from '../../utility';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ width: '97%' }}
        >
            {value === index && (
                <Box sx={{ padding: '12px 24px', marginLeft: '160px', width: '90%' }}>{children}</Box>
            )}
        </div>
    );

}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
};

let customTheme = 'light';
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 36,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 3,
        marginLeft: 0,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(28px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: customTheme === 'dark' ? '#b0b1dd' : '#e9e9e5',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: customTheme === 'dark' ? '#003892' : '#ecdc4d',
        width: 28,
        height: 28,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: customTheme === 'dark' ? '#b0b1dd' : '#e9e9e5',
        borderRadius: 20 / 2,
    },
}));

const UserPreference = ({ open, onClose, preferences, isNewUser, openTab }) => {
    const navigate = useNavigate();
    const localtz = moment.tz.guess()
    const [value, setValue] = React.useState(0);
    const [services, setServices] = useState([]);
    const [preferenceList, setPreferenceList] = useState(preferences);
    const [subscriptions, setSubscriptions] = useState();
    const [selectedService, setSelectedServices] = useState({});
    const [serviceEntity, setServiceEntity] = useState();
    const [filteredServiceEntityData, setFilterServiceEntity] = useState();
    const [selectedTimeZone, setSelectedTimeZone] = useState('');
    const [expanded, setExpanded] = React.useState('panel1');
    const loggedIn = sessionStorage.getItem("isLoggedIn") === 'true' ? true : false;
    const isPilotUser = sessionStorage.getItem("isPilotUser") === 'true' ? true : false;
    const [isSubmit, setSubmit] = useState(false);
    const [isLoading, setLoader] = useState(false);

    const handleAccordChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const options = {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: 2000,
        progress: undefined,
        theme: "light",
    };

    useEffect(() => {
        if (preferences) {
            init();
        }
    }, [preferences]);

    useEffect(() => {
        if (localtz) {
            setSelectedTimeZone(localtz);
        }
    }, []);

    useEffect(() => {
        if (open && loggedIn) {
            setLoader(true);
            getParentServicesList();
            getSubscriptionDetails(preferences["id"])
        }
    }, [open])

    useEffect(() => {
        if (Object.keys(selectedService).length) {
            setServices(createAllServiceList());
        }
    }, [selectedService])

    const init = () => {
        setValue(0);
        if (isNewUser) {
            setValue(2);
        } else if (openTab) {
            setValue(openTab);
        }
        setPreferenceList(preferences);
        // customTheme = preferences['theme'];
    };

    const getSubscriptionDetails = async (id) => {
        try {
            const response = await getSubscriptionData(id);
            if (response && response.data) {
                setSubscriptions(response.data);
            }
        } catch (error) {
            console.log(error);
            if (
                error?.response?.status === 401 ||
                error?.response?.data?.message === "User authorization failed."
            ) {
                onClose();
                navigate("/logout");
            } else {
                toast.error("Error while fetching subscription details, Try reloading!", options);
            }
        }
        setLoader(false);
    }

    const getParentServicesList = async () => {
        try {
            const response = await getParentServices();
            if (response && response.data) {
                const serv = response.data;
                Object.keys(serv).forEach(vendor => {
                    serv[vendor].sort((a, b) => a.localeCompare(b));
                });                
                setServiceEntity(serv);
                setFilterServiceEntity(serv);
                handleServiceSelection(serv);
            }
        } catch (error) {
            console.log(error);
            if (
                error?.response?.status === 401 ||
                error?.response?.data?.message === "User authorization failed."
            ) {
                onClose();
                navigate("/logout");
            } else {
                toast.error("Error while fetching service details, Try reloading!", options);
            }
        }
    };
    

    const handleServiceSelection = (serv) => {
        let isDefaultPreference = true;
        Object.values(vendorDict).map(releaseType => {
            if (releaseType in preferences && preferences[releaseType] !== null) {
                isDefaultPreference = false;
                handleServiceChange(preferences[releaseType].length > 0 ? preferences[releaseType] : [], releaseType)
            }
        })

        // if the default preference, exclude 365 data from selection
        if (isNewUser || isDefaultPreference) {
            Object.keys(vendorDict).map(vendor => {
                if (vendorDict[vendor] in preferences) {
                    let list = [];
                    // Modify new user default selection as per these 4 services in all sources
                    ['Salesforce', 'Tibco', 'Apigee', 'Heroku'].forEach((str) => {
                        if (serv[vendor].includes(str)) {
                            list.push(str);
                        }
                    });
                    if (list.length > 0) handleServiceChange(list, vendorDict[vendor]);
                }
            })
        }
    }

    const createAllServiceList = () => {
        let list = [];
        Object.keys(vendorDict).map(vendor => {
            if (vendorDict[vendor] in selectedService) {
                list = [...list, ...selectedService[vendorDict[vendor]]];
            }
        })
        const quickFilter = preferenceList["quick_services"].filter((serv) => {
            return list.includes(serv);
        });
        setPreferenceList((prevSelectedValues) => ({
            ...prevSelectedValues,
            'quick_services': quickFilter,
        }));
        return Array.from(new Set(list)).sort();
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleThemeChange = (event) => {
        setPreferenceList((prevSelectedValues) => ({
            ...prevSelectedValues,
            'theme': event.target.checked ? 'dark' : 'light',
        }));
        customTheme = event.target.checked ? 'dark' : 'light';
    }

    const handleLongReleaseChange = (event) => {
        setPreferenceList((prevSelectedValues) => ({
            ...prevSelectedValues,
            'show_long_release': event.target.checked,
        }));
    };

    const handleReleaseChange = (event) => {
        setPreferenceList((prevSelectedValues) => ({
            ...prevSelectedValues,
            'long_release': event.target.value,
        }));
    }

    const handleSources = (value, key) => {
        let newServ = selectedService[key];
        if (newServ.indexOf(value) > -1) {
            newServ = newServ.filter(val => val !== value);
        } else {
            newServ = [...newServ, value]
        }
        setSelectedServices((prevSelectedValues) => ({
            ...prevSelectedValues,
            [key]: newServ,
        }));
    }

    const handleServiceChange = (value, key) => {
        setSelectedServices((prevSelectedValues) => ({
            ...prevSelectedValues,
            [key]: value,
        }));
    }

    const handleQuickFiltersChange = (event) => {
        // User should only select maximum of 5 to 6 quick filters
        if (event.target.value.length <= 5) {
            const {
                target: { value },
            } = event;
            setPreferenceList((prevSelectedValues) => ({
                ...prevSelectedValues,
                'quick_services': value,
            }));
        } else {
            options['hideProgressBar'] = true;
            options['autoClose'] = 3000;
            toast.info("You can only select upto 5 quick filters!", options);
        }
    };

    const handleNotifications = (event, key) => {
        if (key === 'release_impact' || key === 'release_action') {
            const value = event.target.value;
            const val = event.target.checked ? [...subscriptions[key], value] : subscriptions[key].filter(val => val !== value);
            setSubscriptions((prevSelectedValues) => ({
                ...prevSelectedValues,
                [key]: val,
            }));
        } else {
            setSubscriptions((prevSelectedValues) => ({
                ...prevSelectedValues,
                [key]: event.target.checked,
            }));
        }
    }

    const handleSubscriptions = (value, key) => {
        let newServ = subscriptions[key];
        if (newServ.indexOf(value) > -1) {
            newServ = newServ.filter(val => val !== value);
        } else {
            newServ = [...newServ, value]
        }
        setSubscriptions((prevSelectedValues) => ({
            ...prevSelectedValues,
            [key]: newServ,
        }));
    }

    const handleTimeZoneChange = (event) => {
        setSelectedTimeZone(event.target.value);
    };

    const handleViewChange = (event) => {
        setPreferenceList((prevSelectedValues) => ({
            ...prevSelectedValues,
            'view': event.target.value,
        }));
    }

    const cancelChanges = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        onClose();
        getParentServicesList();
        init();
    };

    const saveChanges = () => {
        setPreferenceList((prevSelectedValues) => ({
            ...prevSelectedValues,
            'saas': selectedService['saas'] || "",
            '365': selectedService['365'] || "",
            'efr': selectedService['efr'] || "",
            'bcr': selectedService['bcr'] || "",
            'bfr': selectedService['bfr'] || "",
            'emr': selectedService['emr'] || "",
            'odr': selectedService['odr'] || "",
            'snow': selectedService['snow'] || "",
            'dbpswd': selectedService['dbpswd'] || "",
            'fwexc': selectedService['fwexc'] || "",
            'awshealth':selectedService['awshealth'] || "",
        }));
        if (hasEmptyArray) {
            toast.warning("Please select at least one service in Manage Sources!", options);
        } else {
            setSubmit(true);
        }
    };

    const hasEmptyArray = !(Object.values(selectedService).some(value => Array.isArray(value) && value.length > 0));

    useEffect(() => {
        if (isSubmit) {
            //console.log(subscriptions);
            submitChanges();
        }
    }, [isSubmit]);

    const submitChanges = async () => {
        setLoader(true);
        try {
            const [resp1, resp2] = await Promise.allSettled([updateUserPreferences(
                preferenceList
            ), updateSubscriptions(
                subscriptions
            )]);
            if (resp1.value.status === 200 && resp2.value.status === 200) {
                toast.success(
                    "Preferences updated successfully!",
                    options
                );
                sessionStorage.setItem(
                    "preference",
                    JSON.stringify(preferenceList)
                );
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            } else {
                toast.error("Error while updating preferences!", options);
                setLoader(false);
            }

        } catch (error) {
            console.log(error);
            setLoader(false);
            if (
                error?.response?.status === 401 ||
                error?.response?.data?.message === "User authorization failed."
            ) {
                onClose();
                navigate("/logout");
            } else {
                toast.error("Error while updating subscriptions!", options);
            }
        }
    };

    return preferenceList && (
        isLoading ?
            <Loader /> :
            <Dialog className='preference' open={open} onClose={cancelChanges} fullWidth maxWidth="lg">
                <Box sx={{ bgcolor: 'background.paper' }}>
                    <DialogTitle>Preferences</DialogTitle>
                    <DialogContent>
                        <Box sx={{ flexGrow: 1, display: 'flex', height: 400 }}>
                            <Tabs
                                orientation='vertical'
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider', minWidth: 158, position: 'fixed', height: '60%' }}
                            >
                                <Tab label="GENERAL" sx={{ alignItems: 'end' }} {...a11yProps(0)} />
                                <Tab label="NOTIFICATIONS" sx={{ alignItems: 'end' }} {...a11yProps(1)} />
                                <Tab label="MANAGE SOURCES" sx={{ alignItems: 'end' }} {...a11yProps(2)} />
                                <Tab label="QUICK FILTERS" sx={{ alignItems: 'end' }} {...a11yProps(3)} />
                            </Tabs>
                            <TabPanel value={value} index={0} >
                                {/* <div>
                                <h6><strong>Default Theme</strong></h6>
                                <FormControlLabel
                                    className='pl-3'
                                    control={<MaterialUISwitch sx={{ m: 1 }}
                                        checked={preferenceList.theme !== 'light'}
                                        onChange={handleThemeChange} />}
                                    label={preferenceList.theme === 'light' ? 'Light Theme' : 'Dark Theme'}
                                />
                            </div> */}
                                <div>
                                    <h6 className='mb-3'><strong>Default View</strong></h6>
                                    <RadioGroup
                                        row
                                        className="pl-2"
                                        aria-labelledby="view"
                                        name="view"
                                        value={preferenceList.view}
                                        onChange={handleViewChange}
                                        sx={{ ml: 2 }}
                                    >
                                        <FormControlLabel value='list' control={<Radio />} label="List  View" />
                                        <FormControlLabel value='calendar' control={<Radio />} label="Calendar  View" />
                                    </RadioGroup>
                                </div>
                                <div className='my-4'>
                                    <h6 className='mb-2'><strong>Current Timezone</strong></h6>
                                    <FormControl variant="outlined" fullWidth sx={{ m: 3, width: 550 }}>
                                        <InputLabel id="timezone-label">Time Zone</InputLabel>
                                        <Select
                                            disabled
                                            labelId="timezone-label"
                                            id="timezone-select"
                                            value={selectedTimeZone}
                                            onChange={handleTimeZoneChange}
                                            label="Time Zone"
                                        >
                                            <MenuItem key={selectedTimeZone} value={selectedTimeZone}>
                                                {selectedTimeZone}
                                            </MenuItem>
                                            {timezones.map((timezone) => (
                                                <MenuItem key={timezone.tzCode} value={timezone.tzCode}>
                                                    {timezone.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={2} >
                                <div className='mb-4'>
                                    <div className='d-flex align-items-start justify-content-between'>
                                        <h6 className='mb-4 w-75 pr-5 mr-2'><strong>Please select the business services that you are interested in from the sources below, to view the updates related to them</strong></h6>
                                        <a className="d-flex text-sm text-primary justify-content-end w-25 align-items-center" target='_blank' rel='noreferrer' href="https://wiki.autodesk.com/x/nx0XZ">Know about sources
                                            <Lightbulb className='ml-1' sx={{ fontSize: 14 }} />
                                        </a>
                                    </div>
                                    <div className='ml-3'>
                                        {
                                            serviceEntity && Object.keys(serviceEntity).map((vendor) => (
                                                vendorDict[vendor] in preferenceList && (
                                                    <Accordion className='border-top' key={vendor} expanded={expanded === vendor + 'source'} onChange={handleAccordChange(vendor + 'source')}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            aria-controls={vendor + 'source'}
                                                            id={vendor + 'source'}
                                                        > 
                                                            {vendor} {vendor === 'On-Prem DB' ? 'User Events' : eventTypes.includes(vendor) ? 'Events' : 'Releases'}
                                                            <Tooltip title={selectedService[vendorDict[vendor]] ? selectedService[vendorDict[vendor]].join(', ') : ''}>
                                                                <small className='text-primary ml-auto mt-1 mr-3'>{selectedService[vendorDict[vendor]] ? selectedService[vendorDict[vendor]].length : 0} selected</small>
                                                            </Tooltip>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div className='row no-gutters'>
                                                                {serviceEntity[vendor].map(option => (
                                                                    <div className='col-xl-4 col-lg-6 col-sm-6 px-2 align-items-stretch mb-3' key={vendor + option}>
                                                                        <a title={option} className='card w-100 h-100 card-action d-inline-flex text-body'
                                                                            onClick={() => handleSources(option, vendorDict[vendor])}>
                                                                            <div className={`card-body d-flex align-items-center py-2 px-3 ${selectedService[vendorDict[vendor]] && selectedService[vendorDict[vendor]].indexOf(option) !== -1 ? "active" : ""}`}>
                                                                                <Checkbox
                                                                                    edge="start"
                                                                                    checked={selectedService[vendorDict[vendor]] && selectedService[vendorDict[vendor]].indexOf(option) !== -1}
                                                                                    tabIndex={-1}
                                                                                    disableRipple
                                                                                />
                                                                                <span className='text-truncate text-body-sm'>{option}</span>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className='mb-4 toggle-label'>
                                    <div className='d-flex align-items-center'>
                                        <FormControlLabel
                                            sx={{ m: 0 }}
                                            control={
                                                <Switch
                                                    checked={preferenceList["show_long_release"]}
                                                    onChange={handleLongReleaseChange}
                                                />
                                            }
                                            label="Show Long Releases"
                                            labelPlacement="start"
                                        />
                                        <Tooltip title={'Show/Hide the long releases from the view which are running for more than ' + preferenceList["long_release"] + '.'}>
                                            <InfoOutlined fontSize='small' />
                                        </Tooltip>
                                    </div>
                                    {
                                        !preferenceList["show_long_release"] && (
                                            <div className='d-flex align-items-center mt-2 ml-3'>
                                                <span className='mb-3'>Releases running for more than</span>
                                                <FormControl className='Select-sm mb-4' sx={{ m: 1, width: 100 }} size='small'>
                                                    <InputLabel id="duration">Duration</InputLabel>
                                                    <Select
                                                        labelId="duration"
                                                        id="duration"
                                                        value={preferenceList["long_release"]}
                                                        onChange={handleReleaseChange}
                                                        input={<OutlinedInput label="Duration" />}
                                                    >
                                                        {['1 week', '2 weeks', '4 weeks'].map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                <ListItemText primary={name} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                <span className='mb-3'>won't be visible.</span>
                                            </div>
                                        )}
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {subscriptions && (
                                    <>
                                        <div className="mb-3">
                                            <div className='d-flex align-items-center mb-3'>
                                                <h6 className='mb-0 mr-1'><strong>Select the category of notification</strong></h6>
                                                <Tooltip title={'Notifications depend on the "Release Category" (Release, Maintenance, Outage) detail available from the source. '}>
                                                    <InfoOutlined sx={{ fontSize: 16 }} />
                                                </Tooltip>
                                            </div>
                                            <div className='ml-4'>
                                                {/* <FormControlLabel value='inapp' className='d-block mr-5' control={<Checkbox checked={true} disabled />} label="In-App Notifications" /> */}
                                                <FormControlLabel value='release' className='d-inline-block text-body mr-5' control={<Checkbox onChange={(event) => handleNotifications(event, 'release')} checked={subscriptions["release"]} />} label="Release Notifications" />
                                                {
                                                    subscriptions["release"] && (
                                                        <div className='my-3 ml-3 pl-2'>
                                                            <p className='my-2 text-sm'><strong>Send notification for releases which have impact: </strong></p>
                                                            <FormGroup
                                                                className='pl-2'
                                                                row
                                                                aria-labelledby="impact"
                                                                name="impact"
                                                                sx={{ ml: 4 }}
                                                            >
                                                                <FormControlLabel value='high' className='mr-5 text-sm' control={<Checkbox onChange={(event) => handleNotifications(event, 'release_impact')} checked={subscriptions["release_impact"].indexOf('high') !== -1} />} label="High" />
                                                                <FormControlLabel value='medium' className='mr-5 text-sm' control={<Checkbox onChange={(event) => handleNotifications(event, 'release_impact')} checked={subscriptions["release_impact"].indexOf('medium') !== -1} />} label="Medium" />
                                                                <FormControlLabel value='low' className='mr-5 text-sm' control={<Checkbox onChange={(event) => handleNotifications(event, 'release_impact')} checked={subscriptions["release_impact"].indexOf('low') !== -1} />} label="Low" />
                                                                <FormControlLabel value='N/A' className='mr-5 text-sm' control={<Checkbox onChange={(event) => handleNotifications(event, 'release_impact')} checked={subscriptions["release_impact"].indexOf('N/A') !== -1} />} label="N/A" />
                                                            </FormGroup>
                                                            <p className='my-2 text-sm'><strong>Send notification for releases which: </strong></p>
                                                            <FormGroup
                                                                className='pl-2'
                                                                row
                                                                aria-labelledby="action"
                                                                name="action"
                                                                sx={{ ml: 4 }}
                                                            >
                                                                <div className='d-flex align-items-center mr-5'>
                                                                    <FormControlLabel className='text-sm mb-0 mr-1' value='with' control={<Checkbox onChange={(event) => handleNotifications(event, 'release_action')} checked={subscriptions["release_action"].indexOf('with') !== -1} />} label="Require action" />
                                                                    <Tooltip title={'If selected, only the releases that is explicitly calls for any action will be notified.'}>
                                                                        <InfoOutlined sx={{ fontSize: 16 }} />
                                                                    </Tooltip>
                                                                </div>
                                                                <FormControlLabel className='text-sm mr-5' value='without' control={<Checkbox onChange={(event) => handleNotifications(event, 'release_action')} checked={subscriptions["release_action"].indexOf('without') !== -1} />} label="Do not require action/Not mentioned" />
                                                            </FormGroup>
                                                        </div>
                                                    )}
                                                <div>
                                                    <FormControlLabel value='maintenance' className='text-body  mr-5' control={<Checkbox onChange={(event) => handleNotifications(event, 'maintenance')} checked={subscriptions["maintenance"]} />} label="Maintenance Notifications" />
                                                </div>
                                                <div>
                                                    <FormControlLabel value='outage' className='text-body mr-5' control={<Checkbox onChange={(event) => handleNotifications(event, 'outage')} checked={subscriptions["outage"]} />} label="Outage Notifications" />
                                                </div>
                                                <div className='d-flex align-items-center mr-5'>
                                                    <FormControlLabel value='digest' className='text-body mr-1 mb-0' control={<Checkbox onChange={(event) => handleNotifications(event, 'monthly_digest')} checked={subscriptions["monthly_digest"]} />} label="Monthly Digest" />
                                                    <Tooltip title={'If selected, a monthly summary of subscribed releases will be sent as an email.'}>
                                                        <InfoOutlined sx={{ fontSize: 16 }} />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-4'>
                                            <h6 className='mb-2'><strong>Subscribe to the services you wanted to get notified</strong></h6>
                                            <div className='ml-3'>
                                                {
                                                    filteredServiceEntityData && Object.keys(filteredServiceEntityData).map((vendor) => (
                                                        vendorDict[vendor] in preferenceList && (
                                                            <Accordion className='border-top' key={vendor} expanded={expanded === vendor} onChange={handleAccordChange(vendor)}>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMore />}
                                                                    aria-controls={vendor}
                                                                    id={vendor}
                                                                >
                                                                    {vendor} Releases
                                                                    <Tooltip title={subscriptions[vendorDict[vendor]] ? subscriptions[vendorDict[vendor]].join(', ') : ''}>
                                                                        <small className='text-primary ml-auto mt-1 mr-3'>{subscriptions[vendorDict[vendor]] ? subscriptions[vendorDict[vendor]].length : 0} subscribed</small>
                                                                    </Tooltip>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <div className='row no-gutters'>
                                                                        {filteredServiceEntityData[vendor].map(option => (
                                                                            <div className='col-xl-4 col-lg-6 col-sm-6 px-2 align-items-stretch mb-3' key={option}>
                                                                                <a title={option} className='card w-100 h-100 card-action d-inline-flex'
                                                                                    onClick={() => handleSubscriptions(option, vendorDict[vendor])}>
                                                                                    <div className={`card-body d-flex align-items-center py-2 px-3  ${subscriptions[vendorDict[vendor]] && subscriptions[vendorDict[vendor]].indexOf(option) !== -1 ? "active" : ""}`}>
                                                                                        <Checkbox
                                                                                            edge="start"
                                                                                            checked={subscriptions[vendorDict[vendor]] && subscriptions[vendorDict[vendor]].indexOf(option) !== -1}
                                                                                            tabIndex={-1}
                                                                                            disableRipple
                                                                                        />
                                                                                        <span className='text-truncate text-body-sm'>{option}</span>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        ))}
                                                                        {
                                                                            filteredServiceEntityData[vendor].length <= 0 && (
                                                                                <Alert severity="info" className=''>
                                                                                    No services found.
                                                                                </Alert>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        )
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                                }

                            </TabPanel>
                            <TabPanel value={value} index={3} >
                                <h6 className='mb-3'><strong>Select quick filters from your preferred business services <small className="text-secondary">(Max. limit 5)</small></strong></h6>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }} >
                                    <FormControl sx={{ m: 2, width: 550 }} size='small'>
                                        <InputLabel id="data-sources-multiple-checkbox-label">Quick Filters</InputLabel>
                                        <Select
                                            labelId="quick-filters"
                                            id="quick-filters"
                                            multiple
                                            value={preferenceList['quick_services']}
                                            onChange={handleQuickFiltersChange}
                                            input={<OutlinedInput label="Quick Filters" sx={{ padding: '4px' }} />}
                                            renderValue={(selected) => selected.join(', ')}
                                            fullWidth
                                        >
                                            {services.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    <Checkbox checked={preferenceList['quick_services'] && preferenceList['quick_services'].indexOf(option) > -1} />
                                                    <ListItemText primary={option} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </TabPanel>
                        </Box>
                    </DialogContent>
                    <DialogActions className='py-3 px-4'>
                        {!isNewUser && <Button onClick={cancelChanges} variant="outlined">Cancel</Button>}
                        <Button onClick={saveChanges} variant="contained">Save</Button>
                    </DialogActions>
                </Box>
            </Dialog>
    );
};

export default UserPreference;