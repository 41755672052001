import axios from "axios";
import { REACT_APP_API } from "../environment";

const getTokenFromStorage = () => {
  return sessionStorage.getItem("token");
};
const token = getTokenFromStorage();

let headers = {
  "Content-Type": "application/json",
  "Authorization": token,
};

export const getUserPreferences = (email) => {
    headers['email'] = email;
    return axios.get(`${REACT_APP_API}/user_preferences`, { headers: headers });
}

export const getUserIdFromPreference = () => {
  const preference = sessionStorage.getItem("preference");
  if (preference) {
    const preferenceObj = JSON.parse(preference);
    return preferenceObj.id;
  }
  return null;
};

export const updateUserPreferences = (body) => {
    headers['id'] = body['id'];
    return axios.post(`${REACT_APP_API}/update_user_preference`, body, { headers: headers });
}

export const getSubscriptionData = (id) => {
    headers['id'] = id;
    return axios.get(`${REACT_APP_API}/subsciptions`, { headers: headers });
}

export const updateSubscriptions = (body) => {
    headers['id'] = body['id'];
    return axios.post(`${REACT_APP_API}/update_subscriptions`, body, { headers: headers });
}

export const unsubscribeSubscriptions = (body) => {
  headers['id'] = body['id'];
  return axios.post(`${REACT_APP_API}/unsubscribe`, body, { headers: headers });
}

