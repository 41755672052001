import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EventsList from './EventsList';
import "./Calendar.css";
import { ChevronLeft, ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import { Button, FormControl, FormControlLabel, ListItemText, MenuItem, Select, Switch } from '@mui/material';
import { useCallback } from 'react';
import { freezePeriods } from './consts';
import { eventTypes } from '../../utility/consts';

// moment.tz.setDefault('America/Whitehorse');
const localizer = momentLocalizer(moment);

const CalendarView = ({ isDataLoaded, events, preference, releaseId }) => {
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState("");
  const [showLongReleases, setLongRelease] = useState(true);
  const [filtererdEvents, setFilteredEvents] = useState(events);
  const [groupedEvents, setGroupedEvents] = useState([]);
  const [selectedDateFilter, setSelectedDateFilter] = useState("Show All");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const dateFilterList = [
    "Show All",
    "Last 6 months",
    "Last 3 months",
    "Next 3 months",
    "Next 6 months",
  ];

  useEffect(() => {
    if (releaseId && window.location.pathname !== "/") {
      const event = events.find(e => e.release_id === releaseId);
      if (event) {
        setSelectedEvent(event);
      }
    }
  }, [releaseId, events]);

  useEffect(() => {
    setCurrentMonth(
      new Date(selectedDay).toLocaleString("en-us", {
        month: "long",
        year: "numeric",
      })
    );
  }, [selectedDay]);

  // useEffect(() => {
  //   return () => {
  //     moment.tz.setDefault() // reset to browser TZ on unmount
  //   }
  // }, []);

  useEffect(() => {
    const records = filtererdEvents.flatMap((event) => {
      const vendorType = event.Vendor_type;
      // setting up time to null for accurate date comparisons
      const startDate = new Date(event.planned_start_date).setHours(0, 0, 0, 0);
      const endDate = new Date(event.planned_end_date).setHours(0, 0, 0, 0);
      const recordsForEvent = [];
      for (
        let date = new Date(eventTypes.includes(event.Vendor_type) ? endDate : startDate);
        date <= new Date(endDate);
        date.setDate((date).getDate() + 1)
      ) {
        // if (event.release_id === 'SAAS1577') {
        //   console.log(moment(date).format('YYYY-MM-DD'));
        //   console.log(new Date(endDate));
        // }
        // using moment for better timezone management
        recordsForEvent.push({
          Vendor_type: vendorType,
          color: event.color,
          date: moment(date).format('YYYY-MM-DD'),
        });
      }

      return recordsForEvent;
    });

    const groupedRecords = records.reduce((result, record) => {
      const key = `${record.Vendor_type}_${record.date}`;

      if (result[key]) {
        result[key].count += 1;
        result[key].title = `${record.Vendor_type} (${result[key].count})`;
      } else {
        result[key] = {
          title: `${record.Vendor_type} (1)`,
          planned_start_date: record.date,
          planned_end_date: record.date,
          color: record.color,
          count: 1,
        };
      }

      return result;
    }, {});
    setGroupedEvents(Object.values(groupedRecords));
  }, [filtererdEvents]);

  useEffect(() => {
    let filterList = events;
    if (selectedDateFilter !== "Show All") {
      filterList = filterByDate(filterList);
    }
    if (!showLongReleases) {
      filterList = filterLongReleases(filterList)
    }
    setFilteredEvents(filterList);
  }, [showLongReleases, events, selectedDateFilter]);

  const handleEventSelection = async (event) => {
    let date = event?.planned_start_date;
    if (date) {
      date = new Date(date);
      if (!isNaN(date.getTime())) {
        setSelectedDay(date);
      } else {
        console.error("Invalid date:", date);
      }
    } else {
      console.error("Missing or undefined date:", date);
    }
  };

  const filterEventsByDate = (event) => {
    const startDate = eventTypes.includes(event.Vendor_type) 
    ? new Date(event.planned_end_date).setHours(0, 0, 0, 0) 
    : new Date(event.planned_start_date).setHours(0, 0, 0, 0);
    const endDate = new Date(event.planned_end_date).setHours(0, 0, 0, 0);
    const selected = new Date(selectedDay).setHours(0, 0, 0, 0);
    return startDate <= selected && endDate >= selected;
  };

  const toggleMonth = (isNext) => {
    let newDate = new Date();
    if (isNext) {
      newDate = new Date(selectedDay).setMonth(selectedDay.getMonth() + 1);
    } else {
      newDate = new Date(selectedDay).setMonth(selectedDay.getMonth() - 1);
    }
    setSelectedDay(new Date(newDate));
  };

  const handleLongReleaseChange = (event) => {
    setLongRelease(event.target.checked);
  };

  const filterLongReleases = (data) => {
    let long_duration = 7;
    if (preference?.["long_release"] === "2 weeks") long_duration = 14;
    else if (preference?.["long_release"] === "4 weeks") long_duration = 28;
    data = data.filter((event) => {
      const start = new Date(event.planned_start_date);
      const end = new Date(event.planned_end_date);
      return (end - start) / (1000 * 60 * 60 * 24) <= long_duration;
    });
    return data;
  };

  const handleDateChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDateFilter(value);
  };

  const filterByDate = (filterList) => {
    let dateIndex = dateFilterList.indexOf(selectedDateFilter);
    let newDate = new Date();
    const today = new Date();
    if (dateIndex === 1) {
      newDate = newDate.setMonth(newDate.getMonth() - 6);
    } else if (dateIndex === 2) {
      newDate = newDate.setMonth(newDate.getMonth() - 3);
    } else if (dateIndex === 3) {
      newDate = newDate.setMonth(newDate.getMonth() + 3);
    } else if (dateIndex === 4) {
      newDate = newDate.setMonth(newDate.getMonth() + 6);
    }
    return filterList.filter((event) =>
      compareDate(event, new Date(newDate), today)
    );
  };

  const compareDate = (event, newDate, today) => {
    const startDate = new Date(event.planned_start_date).setHours(0, 0, 0, 0);
    const endDate = new Date(event.planned_end_date).setHours(0, 0, 0, 0);
    return today > newDate
      ? startDate <= today && endDate >= newDate
      : startDate <= newDate && endDate >= today;
  };
  
  const checkFreezePeriod = (freezePeriods, selectedDay) => {
    return freezePeriods.some(({ start, end }) => 
      [start, end].some(d => 
        d.getMonth() === selectedDay.getMonth() && d.getFullYear() === selectedDay.getFullYear()
      )
    );
  };

  const dayPropGetter = (date) => {
    const currentDate = new Date(date).setHours(0, 0, 0, 0);
    const selectedDayDate = new Date(selectedDay).setHours(0, 0, 0, 0);
  
    const isInFreezePeriod = freezePeriods.some(period => 
      currentDate >= period.start.setHours(0, 0, 0, 0) && 
      currentDate <= period.end.setHours(0, 0, 0, 0)
    );
  
    const baseStyle = {
      ...(currentDate === selectedDayDate && {
        backgroundColor: '#00000009',
        border: '1px solid #111',
      }),
      ...(isInFreezePeriod && { position: 'relative' })
    };
  
    return {
      style: baseStyle,
      className: isInFreezePeriod ? 'change-freeze-day change-freeze-dot' : undefined,
    };
  };  

  const isFreezeMonth = useCallback(
    () => checkFreezePeriod(freezePeriods, selectedDay),
    [selectedDay]
  );

  return (
    <div className="row mt-4">
      <div className="col-8 pr-0">
        <div className="d-flex align-items-start justify-content-between mb-2">
        <div className="d-flex align-items-center">
            <h5 className='font-weight-bold text-24 mr-2'>{currentMonth}</h5>
            {isFreezeMonth() && (
              <span className="d-flex mr-2 text-xs mb-1">
                {"("}<span className="change-freeze-dot d-inline-flex align-items-center mr-2 ml-1"></span>
                <span className="text-xs d-inline-flex align-items-center mr-1">Planned Change Freeze</span>{")"}
              </span>
            )}
          </div>
          <div className="d-flex align-items-center">
            {/* <FormControlLabel
              sx={{ m: 0 }}
              control={
                <Switch
                  checked={showLongReleases}
                  onChange={handleLongReleaseChange}
                />
              }
              label="Show Long Releases"
            /> */}

            <FormControl className="source-list ml-2" size="small">
              <Button
                variant="contained"
                disableElevation
                className="mr-1"
                size="small"
                endIcon={<KeyboardArrowDown />}
              >
                {selectedDateFilter}
              </Button>
              <Select
                labelId="source"
                id="source"
                value={selectedDateFilter}
                onChange={handleDateChange}
                sx={{ width: "100%" }}
              >
                {dateFilterList.map((date) => (
                  <MenuItem key={date} value={date}>
                    <ListItemText primary={date} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <button
              className="btn btn-sm btn-outline-primary mr-2 ml-2"
              onClick={() => {
                setSelectedDay(new Date());
              }}
            >
              Today
            </button>
            <div
              className="btn-group btn-group-sm"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  toggleMonth(false);
                }}
              >
                <ChevronLeft />
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  toggleMonth(true);
                }}
              >
                <ChevronRight />
              </button>
            </div>
          </div>
        </div>
        <Calendar
          className="calendar-container"
          localizer={localizer}
          views={"month"}
          events={groupedEvents}
          popup
          date={selectedDay}
          selectable
          startAccessor={(event) => {
            return new Date(event.planned_start_date);
          }}          
          endAccessor={(event) => {
            return new Date(event.planned_end_date);
          }}
          dayPropGetter={dayPropGetter}
          eventPropGetter={(myEventsList) => {
            const bg = myEventsList.color
              ? myEventsList.color["hex"] + '30'
              : "#8b8cd630";
            const color = myEventsList.color
              ? myEventsList.color["hex"]
              : "#8b8cd6";
            return { style: { backgroundColor: bg, color: color, borderColor: color } };
          }}
          onSelectEvent={handleEventSelection}
          onNavigate={(date) => {
            setSelectedDay(date);
          }}
        />
      </div>
      <div className="col-4">
        {selectedDay && (
          <EventsList
            isDataLoaded={isDataLoaded}
            selectedDay={selectedDay}
            events={filtererdEvents.filter(filterEventsByDate)}
            event={selectedEvent}
          />
        )}
      </div>
    </div>
  );
};

export default CalendarView;
