import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button
} from "@mui/material";
import { ReactComponent as Apigee } from '../../assets/logo/apigee.svg';
import { ReactComponent as Sendwithus } from '../../assets/logo/sendwithus.svg';
import { ReactComponent as RingDNA } from '../../assets/logo/ringdna.svg';
import { ReactComponent as Salesforce } from '../../assets/logo/salesforce.svg';
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { loginSSO } from "../../Services";
import { Lightbulb } from "@mui/icons-material";
import Loader from "../Common/Loader/Loader";

function LoginPage() {
  const navigate = useNavigate();
  const [isLoading, setLoader] = useState(false);
  const redirectPath = sessionStorage.getItem("path");
  let directLogin = sessionStorage.getItem('directLogin') === 'true' ? true : false;

  const handleLogin = async () => {
    setLoader(true);
    try {
      const response = await loginSSO();
      if (response.status === 200) {
        let location = response?.data?.location;
        if (location) {
          window.location.href = location;
        }
      } else {
        navigate('/error')
      }

    } catch (error) {
      console.log(error.message);
      if (
        error?.response?.status === 401 ||
        error?.response?.data?.message === "User authorization failed."
      ) {
        navigate("/logout");
      } else {
        navigate("/error");
      }
    }
  };

  useEffect(() => {
    if (redirectPath && directLogin) {
      sessionStorage.removeItem('directLogin')
      handleLogin();
    }
  }, [redirectPath]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const code = urlSearchParams.get("code");
    if (code) {
      navigate("/redirect", { state: { code: code } });
    }
  });

  return isLoading ?
    <Loader /> :
    (
      <div className="login-page">
        <AppBar
          position="static"
          sx={{ backgroundColor: "#000000", height: "60px" }}
        >
          <Toolbar>
            <a className="navbar-brand" href="/">
              <img
                src="https://autobots-app-prd.s3.amazonaws.com/web/static/autodesk-logo-primary-rgb-white-large.png"
                className="img-responsive"
                style={{ width: "210px" }}
                alt="AutoDesk"
              />
            </a>
            <div className="d-flex align-items-center w-100">
              <ul className="navbar-nav ml-auto flex-row mt-2 mt-lg-0">
                <li className="nav-item">
                  <a className="nav-link d-flex" target='_blank' rel='noreferrer' href="https://wiki.autodesk.com/x/nx0XZ">Know more
                    <Lightbulb className='ml-1' sx={{ fontSize: 16, marginTop: '2px' }} />
                  </a>
                </li>
              </ul>
            </div>
          </Toolbar>
        </AppBar>
        <div className="top-band p-5 text-center d-flex justify-content-center align-items-center">
          <div className="mx-auto my-auto">
            <h1 style={{ fontSize: "2.25rem", fontWeight: '900' }}>Enterprise Applications Update Calendar</h1>
            <h5 className="mt-3">Stay Ahead with Planned Updates.</h5>
            <Button
              color="primary"
              variant="contained"
              size="large"
              sx={{ mt: 5 }}
              endIcon={<LockOpenIcon sx={{ mb: '4px' }} />}
              onClick={handleLogin}
            >
              Login with SSO
            </Button>
          </div>
        </div>
        <div className="text-center p-3">
          <div className="row no-gutters max-w-1130 mx-auto">
            <div className="col-9 mx-auto mt-3">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <img
                  src={require(`../../assets/logo/sharepoint.png`)}
                  className="img-responsive mx-2"
                  height="44"
                  alt="Sharepoint"
                />
                <img
                  src={require(`../../assets/logo/exchange-online.jpg`)}
                  className="img-responsive mx-2"
                  height="40"
                  alt="Exchange"
                />
                <img
                  src={require(`../../assets/logo/windows.png`)}
                  className="img-responsive mx-2"
                  height="44"
                  alt="Windows"
                />
                <Salesforce className="mx-2" />
                <img
                  src={require(`../../assets/logo/teams.png`)}
                  className="img-responsive mx-2"
                  height="40"
                  alt="Teams"
                />
                <img
                  src={require(`../../assets/logo/heroku.png`)}
                  className="img-responsive mx-2"
                  height="26"
                  alt="Heroku"
                />
              </div>
              <div className="d-flex justify-content-around align-items-center mb-4">
                <RingDNA className="mx-2" />
                <img
                  src={require(`../../assets/logo/office-app.jpg`)}
                  className="img-responsive mx-2"
                  height="52"
                  alt="Office 360 App"
                />
                <img
                  src={require(`../../assets/logo/planner.png`)}
                  className="img-responsive mx-2"
                  height="40"
                  alt="Planner"
                />
                <Apigee className="mx-2" />
                <img
                  src={require(`../../assets/logo/nice.png`)}
                  className="img-responsive mx-2"
                  height="19"
                  alt="Nice"
                />
                <img
                  src={require(`../../assets/logo/stream.png`)}
                  className="img-responsive mx-2"
                  height="32"
                  alt="Stream"
                />
              </div>
              <div className="d-flex justify-content-around align-items-center mb-4 mx-5">
                <img
                  src={require(`../../assets/logo/office-suite.png`)}
                  className="img-responsive mx-2"
                  height="48"
                  alt="Suite"
                />
                <img
                  src={require(`../../assets/logo/onedrive.png`)}
                  className="img-responsive mx-2"
                  height="48"
                  alt="One Drive"
                />
                <img
                  src={require(`../../assets/logo/power-automate.png`)}
                  className="img-responsive mx-2"
                  height="48"
                  alt="Power Automate"
                />
                <Sendwithus className="mx-2" />
                <img
                  src={require(`../../assets/logo/powerbi.png`)}
                  className="img-responsive mx-2"
                  height="40"
                  alt="Powerbi"
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            {/* <p className="mb-1 text-sm">Powered by ICE Tools & Automation Team</p> */}
            <p className="text-sm border-top px-3 py-2 d-inline border-black">
              For support, reach out to us on
              <a
                className="text-primary text-underline ml-1"
                target="_blank"
                rel="noreferrer"
                href="https://autodesk.enterprise.slack.com/archives/C0686JG0H41"
              >
                Slack
              </a>.
            </p>
          </div>
        </div>
      </div>
    );
}

export default LoginPage;
