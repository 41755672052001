import React, { useState, useEffect, useRef } from "react";
import * as echarts from "echarts";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Grid from "@mui/material/Grid";
import { KeyboardArrowDown, Info } from '@mui/icons-material';
import { Button, Select, MenuItem, FormControl, Typography, Tooltip, IconButton, ListItemText } from "@mui/material";
import "./TimelineView.css";
import EventDetail from '../Common/EventDetails/EventDetails';
import { getChartOption, initializeChartEvents, loadingOption } from "./ChartUtils";
import { eventTypes } from "../../utility/consts";
const calculateMinMaxDates = (events) => {
  const validEvents = events.filter(e => !e.isDummy);
  const eventDates = validEvents
  .map(e => eventTypes.includes(e.Vendor_type)
    ? new Date(e.planned_end_date)
    : new Date(e.planned_start_date))
  .filter(d => !isNaN(d.getTime()));
  const minDate = dayjs(Math.min(...eventDates.map(d => d.getTime())));
  const maxDate = dayjs(Math.max(...eventDates.map(d => d.getTime())));
  return { minDate, maxDate };
};
const processEventData = (events) => {
  const dataForPlot = events.reduce((acc, event) => {
    const startDate = eventTypes.includes(event.Vendor_type)
    ? new Date(event.planned_end_date) 
    : new Date(event.planned_start_date);

    if (startDate && !isNaN(startDate.getTime())) {
      const key = `${startDate}_${event.parent_service}`;
      acc[key] = acc[key] || { date: startDate.getTime(), service: event.parent_service, count: 0, isDummy: event.isDummy };
      acc[key].count++;
    }
    return acc;
  }, {});
  return Object.values(dataForPlot).map(({ date, service, count, isDummy }) => ({
    value: [date, count],
    name: service,
    isDummy
  }));
};

const dateFilterList = [
  { id: 1, label: "Current Week", startDate: dayjs().startOf("week"), endDate: dayjs().endOf("week") },
  { id: 2, label: "Last 6 months", startDate: dayjs().subtract(6, "month"), endDate: dayjs() },
  { id: 3, label: "Last 3 months", startDate: dayjs().subtract(3, "month"), endDate: dayjs() },
  { id: 4, label: "Next 3 months", startDate: dayjs(), endDate: dayjs().add(3, "month") },
  { id: 5, label: "Next 6 months", startDate: dayjs(), endDate: dayjs().add(6, "month") },
  { id: 6, label: "Custom Date", startDate: null, endDate: null }
];

const TimelineView = ({ isDataLoaded, events }) => {
  const chartRef = useRef(null);
  const initialStartTime = dayjs().startOf("week");
  const initialEndTime = initialStartTime.add(6, "day");

  const [startTime, setStartTime] = useState(initialStartTime);
  const [endTime, setEndTime] = useState(initialEndTime);
  const [pickerStartTime, setPickerStartTime] = useState(initialStartTime);
  const [pickerEndTime, setPickerEndTime] = useState(initialEndTime);
  const [minDate, setMinDate] = useState(initialStartTime);
  const [maxDate, setMaxDate] = useState(initialEndTime);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dateRangeOption, setDateRangeOption] = useState(1);

  const handleToolboxClick = (action) => {
    const chartInstance = echarts.getInstanceByDom(chartRef.current);
    if (action === "restore") chartInstance.dispatchAction({ type: "restore" });
    setDateRangeOption(1);
  };

  const handleDateChange = (dateSetter, timexSetter, currentValue) => (newValue) => {
    newValue = dayjs(newValue);
    dateSetter(newValue);
    if (newValue !== currentValue && (dateSetter !== setStartTime || !newValue.isAfter(pickerEndTime))) timexSetter(newValue);
  };

  const handleDateRangeOptionChange = (event) => {
    const option = event.target.value;
    setDateRangeOption(option);

    const selectedOption = dateFilterList.find(dateOption => dateOption.id === option);
    const newStartTime = selectedOption.startDate || startTime;
    const newEndTime = selectedOption.endDate || endTime;

    setStartTime(newStartTime);
    setEndTime(newEndTime);
    setPickerStartTime(newStartTime);
    setPickerEndTime(newEndTime);
  };

  const openModal = (releaseId) => {
    const event = events.find(e => e.release_id === releaseId);
    setSelectedEvent(event);
    setIsModalOpen(true);

    const tooltipDOM = document.querySelector(".chart-container > div:nth-child(2)");
    if (tooltipDOM) tooltipDOM.style.display = 'none';
  };

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);

    myChart.showLoading('default', loadingOption);

    const validEvents = events.filter(e => !e.isDummy);
    const { minDate, maxDate } = calculateMinMaxDates(validEvents);

    const firstEventDate = minDate.subtract(10, "year").toDate();
    const lastEventDate = maxDate.add(10, "year").toDate();

    const dummyEvents = [
      { planned_start_date: firstEventDate, parent_service: 'Dummy Service', release_id: 'dummy1', isDummy: true },
      { planned_start_date: lastEventDate, parent_service: 'Dummy Service', release_id: 'dummy2', isDummy: true }
    ];

    const allEvents = [...events, ...dummyEvents];

    setMinDate(minDate);
    setMaxDate(maxDate);

    const uniqueServices = [...new Set(allEvents.map(event => event.parent_service))];
    const seriesData = processEventData(allEvents);
    const chartOption = getChartOption(allEvents, uniqueServices, seriesData, startTime, endTime, isDataLoaded);
    myChart.setOption(chartOption);

    initializeChartEvents(myChart, setStartTime, setEndTime, initialStartTime, initialEndTime, openModal, setPickerStartTime, setPickerEndTime);

    if (isDataLoaded) {
      myChart.hideLoading();
    }

    window.onresize = myChart.resize;
    return () => myChart.dispose();
  }, [events, pickerStartTime, pickerEndTime, isDataLoaded]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="card bg-white timeline-container w-100 mt-3 rounded-0">
        <div className="py-3 card-body css-1gsv261">
          <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
            <Grid item xs={12} md={5} container direction="column" justifyContent="flex-start">
              <Grid item container alignItems="center">
                <Tooltip title="All the above filters can be utilized in the Timeline.">
                  <IconButton size="small" disabled={!isDataLoaded}>
                    <Info sx={{ fontSize: '1.15rem' }} />
                  </IconButton>
                </Tooltip>
                <Typography variant="subtitle2" color="textSecondary" display="inline" style={{ marginLeft: 2 }}>
                  {`Showing the releases from ${startTime.format('DD MMM YYYY')} to ${endTime.format('DD MMM YYYY')}.`}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={7} container justifyContent="flex-end" alignItems="center" spacing={2}>
              <Grid item>
                <FormControl className="source-list" size="small" sx={{ width: '148px' }} disabled={!isDataLoaded}>
                  <Button
                    variant={isDataLoaded ? "contained" : "outlined"}
                    disableElevation
                    size="small"
                    endIcon={<KeyboardArrowDown />}
                    sx={{ width: '100%' }}
                    disabled={!isDataLoaded}
                  >
                    {dateFilterList.find(option => option.id === dateRangeOption)?.label}
                  </Button>
                  {isDataLoaded && (
                  <Select
                    labelId="date-range-select-label"
                    value={dateRangeOption}
                    onChange={handleDateRangeOptionChange}
                    sx={{ width: '100%' }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          minWidth: '148px !important',
                        },
                      },
                    }}
                    disabled={!isDataLoaded}
                  >
                    {dateFilterList.map(({ id, label }) => (
                      <MenuItem key={id} value={id}>
                        <ListItemText primary={label} />
                      </MenuItem>
                    ))}
                  </Select>
                  )}
                </FormControl>
              </Grid>
              {dateRangeOption === 6 && (
                <>
                  <Grid item>
                    <DatePicker
                      sx={{ width: "160px" }}
                      format="DD MMM YYYY"
                      label="From"
                      slotProps={{
                        textField: {
                          size: "small",
                          InputProps: {
                            className: 'form-control form-control-sm border-0'
                          }
                        }
                      }}
                      value={startTime}
                      onChange={handleDateChange(setStartTime, setPickerStartTime, pickerStartTime)}
                      minDate={minDate}
                      maxDate={endTime.isAfter(maxDate) ? maxDate : endTime.isBefore(minDate) ? minDate : endTime}
                      disabled={!isDataLoaded}
                    />
                  </Grid>
                  <Grid item sx={{ pl: '8px !important' }}>–</Grid>
                  <Grid item sx={{ pl: '8px !important' }}>
                    <DatePicker
                      sx={{ width: "160px" }}
                      format="DD MMM YYYY"
                      label="To"
                      slotProps={{
                        textField: {
                          size: "small",
                          InputProps: {
                            className: 'form-control form-control-sm border-0'
                          }
                        }
                      }}
                      value={endTime}
                      onChange={handleDateChange(setEndTime, setPickerEndTime, pickerEndTime)}
                      minDate={startTime.isBefore(minDate) ? minDate : startTime.isAfter(maxDate) ? maxDate : startTime}
                      maxDate={maxDate}
                      disabled={!isDataLoaded}
                    />
                  </Grid>
                </>
              )}
              <Grid item>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => handleToolboxClick("restore")}
                  disabled={!isDataLoaded}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
        {selectedEvent && (
          <EventDetail event={selectedEvent} isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        )}
        <div ref={chartRef} className="chart-container p-3"></div>
      </div>
    </LocalizationProvider>
  );

};

export default TimelineView;
