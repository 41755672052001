import { createTheme } from '@mui/material/styles';
const preference = sessionStorage.getItem('preference');
const mode = preference ? JSON.parse(preference)['theme'] : 'light';

export const Theme = createTheme({
  typography: {
    fontFamily: 'Artifakt Element,serif',
  },
  palette: {
    mode: mode,
    primary: {
      main: mode === 'dark'? '#7273f2':'#000000',
    },
    secondary: {
      main: '#6c757d',
    }
  },
});