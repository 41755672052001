import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Loader.css";
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';


const Loader = () => {

  const [message, setMessage] = useState("");

  useEffect(() => {
    let action = sessionStorage.getItem('isLoggedIn') === true ? true : false;
    if (action) {
      setMessage("Hold on, we're getting things ready...");
    } else setMessage("Please wait, we are loading your preferred updates...")
  }, []);

  return (
    <div className="">
      <div className="blurred-background">
        <div className="loader-container">
          <div className="loader" style={{ width: "60px", height: "150px" }} />
        </div>
        <span style={{ display: 'flex', color: '#000', position: 'fixed', top: '35%', left: '50%', transform: 'translate(-50%, -50%)' }}>{message} <HourglassBottomRoundedIcon /></span>
      </div>
    </div>

  );
};

Loader.propTypes = {
  size: PropTypes.string,
  duration: PropTypes.number
};

Loader.defaultProps = {
  size: "50px",
  duration: 1.5
};

export default Loader;
